import React, { ReactNode, Suspense } from "react";
import { Header, LeftBanner } from "./components";
import { Container, styled } from "@mui/material";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";

type Props = { children?: ReactNode };

const AuthLayout = ({ children }: Props) => {
  const { isMobile } = useMediaBreakpoints();
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header />
      <Main>
        {!isMobile && <LeftBanner />}
        <Suspense fallback={null}>{children}</Suspense>
      </Main>
    </div>
  );
};

const Main = styled(Container)(({ theme }) => ({
  height: "calc(100vh - 73px)",
  backgroundColor: "#fff",
  display: "flex",
  // maxWidth: 1620,
  // margin: "0 auto",
  ".main": {
    width: "calc(50% + 48px)",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    position: "relative",
    alignItems: "center",
    ".scroll-box": {
      overflow: "hidden",
      overflowY: "scroll",
      width: "100%",
    },
    ".email-input": {
      input: {
        paddingLeft: 4,
      },
    },
    ".step-title": {
      color: "#333333",
    },
    ".step-desc": {
      color: "#666666",
    },
    "button.Mui-disabled": {
      backgroundColor: "#11111140",
      color: "#fff",
      "-webkit-text-fill-color": "#fff",
    },
    ".submit-btn": {
      borderRadius: 12,
    },
    ".link": {
      textDecoration: "underline",
    },
  },
  ".tmp": {
    height: 32,
  },
  [theme.breakpoints.down("md")]: {
    height: "calc(100vh - 57px)",
    ".main": {
      width: "100%",
      justifyContent: "flex-start",
    },
    ".step-title": {
      fontSize: 20,
      // textAlign: "center",
    },
    ".step-desc": {
      fontSize: 14,
    },
  },
}));

export default AuthLayout;
