import { LgTextSemiBold, Post, Row, ViewAllText } from "Components";
import * as React from "react";
import { useIntl } from "react-intl";
import { PostType } from "types/post";
import { useSupplierContext } from "..";

export interface ISupplierPostsProps {
  posts: PostType[];
}

export default function SupplierPosts({ posts }: ISupplierPostsProps) {
  const { setSelectedTab, supplierDetail } = useSupplierContext();
  const fornattedPosts: PostType[] = React.useMemo(
    () =>
      posts.map((post) => {
        const { farm_name, farm_avatar, name } = supplierDetail;
        return { ...post, farm_name, farm_avatar, farm_id: name };
      }),
    [posts, supplierDetail]
  );

  const t = useIntl();
  if (posts.length < 1) return null;

  const onClickViewAll = () => {
    setSelectedTab("post");
  };

  return (
    <>
      <Row justifyContent="space-between">
        <LgTextSemiBold>{t.formatMessage({ id: "post" })}</LgTextSemiBold>
        <ViewAllText onClick={onClickViewAll} />
      </Row>

      {fornattedPosts.map((post, i) => (
        <Post {...{ post }} key={i} />
      ))}
    </>
  );
}
