import { Stack, StackProps, Typography } from "@mui/material";
import { IntlMsg, LoadingSkeleton, SuggestProduct } from "Components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGetSidebarItems } from "services/api/company";

const SuggestedProducts = (props: StackProps) => {
  const { supplierId } = useParams<{ supplierId: string }>();

  const [isLoading, setisLoading] = useState(true);
  const [items, setitems] = useState<any>([]);

  const fetchData = async () => {
    let data = await apiGetSidebarItems({ farm_id: supplierId });
    setitems(data?.data?.message?.data?.suggested_items || []);
    setisLoading(false);
  };

  useEffect(() => {
    setisLoading(true);
    fetchData();
  }, [supplierId]);

  return (
    <Stack spacing={2} {...props}>
      <Typography className="bold" component="h2">
        <IntlMsg id="suggested_products" />
      </Typography>
      {isLoading && <LoadingSkeleton />}

      {!isLoading && (
        <>
          {items?.map((item: any, i: number) => {
            return <SuggestProduct product={item} />;
          })}
        </>
      )}
    </Stack>
  );
};

export default SuggestedProducts;
