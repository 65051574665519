import { Box } from "@mui/material";
import React from "react";

import peopeImg from "../../../../Assets/images/404.png";
import peopeBgImg from "../../../../Assets/images/404_bg.png";
import useStyles from "./styles";

const RightBox = () => {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <img src={peopeImg} alt="img" className="mainImg" />
      <img src={peopeBgImg} alt="img" className="bgImg" />
    </Box>
  );
};

export default RightBox;
