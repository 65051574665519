import { TopProducts as TopProductsCom } from "./components";

const TopProducts = () => {
  return (
    <>
      <TopProductsCom />
    </>
  );
};

export default TopProducts;
