import { Box, Link, Stack, Typography, styled } from "@mui/material";
import {
  AcceptButton,
  ConfirmButton,
  CountryFlag,
  Img,
  Row,
  SmText,
  StyledA,
  TextSemibold,
  TimeDiff,
} from "Components";
import { DIRECT_PATHS } from "constants/routes";
import dayjs from "dayjs";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import useNavigate from "hooks/useNavigate";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getCompany } from "redux/selectors/company";
import { formatImgUrl } from "utils/helpers";

type Props = {};

export const AboutSupplier = (props: Props) => {
  const intl = useIntl();
  const { openNewTab } = useNavigate();
  const company = useSelector(getCompany);
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();

  const onClickCompany = () => {
    openNewTab(DIRECT_PATHS.companyDetail(company?.name));
  };

  return (
    <StyledAboutSupplier>
      <Typography className="title" mb={2} component="h2">
        {intl.formatMessage({ id: "know_your_supplier" })}
      </Typography>

      <Box className={isMobile ? "" : "content"} p={isMobile ? 0 : 2}>
        <BaseSupplierInfors />

        {!isMobile && (
          <Row mt={4} className="buttons">
            <ConfirmButton onClick={onClickCompany}>
              {intl.formatMessage({ id: "view_products" })}
            </ConfirmButton>
            <AcceptButton onClick={onClickCompany}>
              {intl.formatMessage({ id: "visit_supplier_page" })}
            </AcceptButton>
          </Row>
        )}
      </Box>
    </StyledAboutSupplier>
  );
};

export const BaseSupplierInfors = () => {
  const t = useIntl();
  const company = useSelector(getCompany) || {};
  const { company_country, country_code, last_active } = company;
  const NOW = dayjs();

  return (
    <StyledBaseSupplierInfors direction="row" spacing={2}>
      <Img
        src={formatImgUrl(company?.farmImage, 2)}
        alt="img"
        className="company_img"
      />
      <Stack spacing={1}>
        <StyledA href={DIRECT_PATHS.companyDetail(company?.name)}>
          <TextSemibold className="company_name" component="h3">
            {company?.farmName}
          </TextSemibold>
        </StyledA>

        {last_active && (
          <SmText className="active-text">
            {t.formatMessage(
              { id: "active_value_ago" },
              {
                value: (
                  <TimeDiff
                    endTime={NOW.format()}
                    startTime={dayjs(last_active).format()}
                  />
                ),
              }
            )}
          </SmText>
        )}

        <CountryFlag fd_country={company_country} {...{ country_code }} />
      </Stack>
    </StyledBaseSupplierInfors>
  );
};

const StyledBaseSupplierInfors = styled(Stack)({
  fontSize: 16,

  ".company_name": {
    color: "#1D2939",
  },
  ".company_img": {
    width: 64,
    height: 64,
    objectFit: "contain",
  },
  ".flag": {
    width: 16,
    height: 16,
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});

const StyledAboutSupplier = styled(Stack)({
  width: "100%",
  ".content": {
    backgroundColor: "#EAECF0",
  },
  ".title": {
    fontSize: 16,
    fontWeight: 700,
  },
  ".buttons": {
    button: {
      flex: 1,
      padding: "10px 18px",
    },
  },
});
