import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as FarmItemInformation } from "./FarmItemInformation";
export { default as TradeAssist } from "./TradeAssist";
export const ItemBanners = withLoading(lazy(() => import("./ItemBanners")));
export const TableInformations = withLoading(
  lazy(() => import("./TableInformations"))
);
export const BottomItems = withLoading(lazy(() => import("./BottomItems")));
export const SuggestedItems = withLoading(
  lazy(() => import("./SuggestedItems"))
);
export const MainInformations = withLoading(
  lazy(() => import("./MainInformations"))
);