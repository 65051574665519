import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiContainer-root": {
      padding: 0
    }
  },
  leftBox: {
    alignItems: "center",
    justifyContent: "center",
  },
}));
