import defaultImage from "../Assets/images/default_img.webp";
import defaultItemImage from "../Assets/images/default_img.webp";
import { getConfigs } from "../Config";

const configURL = getConfigs();

const freshdiSubdomain = ["tr", "trace", "freshdi"];

const checkListProtocols = (link = "") => {
  let protocols = ["http", "https"],
    checkListProtocols = false;
  protocols?.map((prototal) => {
    if (link?.indexOf(prototal) > -1) {
      checkListProtocols = true;
    }
    return prototal;
  });
  return checkListProtocols;
};

const getMatchImageSize = (type = 0) => {
  switch (type) {
    case 1:
      return "128x128";
    case 2:
      return "240x240";
    case 3:
      return "350x350";
    case 4:
      return "480x480";
    case 5:
      return "640x640";
    case 6:
      return "720x720";
    case 7:
      return "1024x1024";
    default:
      return "240x240";
  }
};

const getThumbnail = (url, type) => {
  let thumbnailImg = null;
  if (url) {
    thumbnailImg = url.replace("m3u8", "jpeg");
  }
  return `${configURL.IMG_SERVER_URL}/${getMatchImageSize(
    type
  )}${thumbnailImg}`;
};

const formatImageUrl = (suffix, type = null) => {
  return suffix
    ? checkListProtocols(suffix)
      ? suffix
      : type
      ? `${configURL.IMG_SERVER_URL}/${getMatchImageSize(type)}${suffix}`
      : `${configURL.BASE_URL}${suffix}`
    : defaultImage;
};

const checkIsVideo = (suffix) => {
  let isVideo = false;
  if (suffix) {
    isVideo = suffix?.indexOf(".m3u8") > -1;
  }
  return isVideo;
};

const formatImgUrl = (suffix, type = -1, isThumbnail = false) => {
  return suffix
    ? checkListProtocols(suffix)
      ? suffix
      : checkIsVideo(suffix)
      ? isThumbnail
        ? getThumbnail(suffix, type)
        : `${configURL.IMG_SERVER_URL}${suffix}`
      : type > -1
      ? `${configURL.IMG_SERVER_URL}/${getMatchImageSize(type)}${suffix}`
      : `${configURL.BASE_URL}${suffix}`
    : defaultItemImage;
};

const chainUrl = (suffix) => {
  return `${configURL.CHAIN_SERVER_URL}${suffix}`;
};

const websocketUrl = () => {
  return configURL.WEBSOCKET_SERVER_URL;
};

const formatBlogThumbnail = (suffix) => {
  return `${configURL.BASE_URL}${suffix}`;
};

const isSubOrPridomain = () => {
  let host = window.location.host;
  let hostPartis = host.split(".");
  return (
    (freshdiSubdomain.indexOf(hostPartis[0]) < 0 && hostPartis?.length > 1) ||
    (host?.indexOf("freshdi") < 0 && host?.indexOf("localhost") < 0)
  );
};

const getFarmIdInUrl = () => {
  let hostPartis = window.location.host.split(".");
  let pathPartis = window.location.pathname.split("/")?.filter((p) => p !== "");
  if (isSubOrPridomain()) {
    return hostPartis[0];
  }
  return pathPartis?.length > 0 ? decodeURI(pathPartis[0]) : null;
};

const checkValidString = (string) => {
  return string && string?.toString()?.trim() !== "" ? true : false;
};

const formatError = (err) => {
  let defaultError = {};
  try {
    const errorRes = (JSON.parse(err?.response?.data?._server_messages) ||
      "[{}]")[0];
    return JSON.parse(errorRes) || defaultError;
  } catch (error) {
    return defaultError;
  }
};

export {
  formatImageUrl,
  formatBlogThumbnail,
  chainUrl,
  formatImgUrl,
  websocketUrl,
  checkIsVideo,
  getFarmIdInUrl,
  checkValidString,
  isSubOrPridomain,
  formatError,
};
