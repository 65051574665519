import { defineMessages } from "react-intl";

const currentPath = "Pages.PageNotPage.components.LeftBox";

export default defineMessages({
  notFound: {
    id: `${currentPath}.notFound`,
    defaultMessage: "Bạn ơi <br></br>Trang bạn tìm kiếm không tồn tại :(",
  },
  backPrePage: {
    id: `${currentPath}.backPrePage`,
    defaultMessage: "Quay lại trang trước",
  },
  home: {
    id: `${currentPath}.home`,
    defaultMessage: "Về trang chủ",
  },
});
