import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmLogoutPopup } from ".";
import { PATHS } from "constants/routes";
import IntlMsg from "Components/IntlMsg";
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import { getUser } from "redux/selectors/user";
import { useIntl } from "react-intl";
import { BIZ_DOMAIN } from "constants/schemas";
import { useLanguageContext } from "Components/LanguageProvider";
import { menuCountDataSelector } from "redux/selectors/common";
import useChat from "hooks/useChat";

const useCustomHooks = () => {
  const { language } = useLanguageContext();
  const history = useHistory();
  const user = useSelector(getUser);
  const { onLogout } = useAuth();
  const intl = useIntl();
  const { onClearChatKey } = useChat();
  const menuCountStoreData = useSelector(menuCountDataSelector);

  // state
  const [dialogConfig, setDialogConfig] = useState({ open: false });
  const [expanded, handleExpand] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleClickShowAuth = (e) => {
    handleExpand(!expanded);
    setAnchorEl(e?.currentTarget);
  };

  const onClickLogout = () => {
    handleExpand(false);
    setDialogConfig({
      open: true,
      content: (
        <ConfirmLogoutPopup
          onClickCancel={onCloseDialog}
          onClickConfirm={onHandleLogout}
        />
      ),
    });
  };

  // Logout
  const onHandleLogout = async () => {
    localStorage.removeItem("aufi_cus_pho");
    localStorage.removeItem("aufi_cus_nam");
    await onLogout();
    onCloseDialog();
    onClearChatKey();
  };

  // XỬ LÝ ĐÓNG DIALOG
  const onCloseDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false });
    handleExpand(false);
  };

  const onClickMenuItem = (menuItem) => {
    const { path, href } = menuItem;
    if (path || href) {
      if (href) {
        window.location.href = href;
      } else {
        history.push(path);
      }
      onCloseDialog();
    } else {
      menuItem?.event && menuItem?.event();
    }
  };

  const menuItems = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "my_sourcing_requests" }),
        path: PATHS.requestQuotation,
      },
      {
        label: intl.formatMessage({ id: "received_quotations" }),
        path: PATHS.receivedQuotation,
        count: menuCountStoreData?.count_quotations,
      },
      {
        label: <IntlMsg id="inquiries" />,
        path: PATHS.inquiries,
      },
      {
        label: intl.formatMessage({ id: "my_account_settings" }),
        path: PATHS.profile,
      },
      {
        label: intl.formatMessage({ id: "switch_to_supplier_account" }),
        href: BIZ_DOMAIN,
      },
      {
        label: intl.formatMessage({ id: "Signout" }),
        event: onClickLogout,
      },
    ],
    [user, language, menuCountStoreData]
  );

  return {
    menuItems,
    dialogConfig,
    anchorEl,
    expanded,
    onCloseDialog,
    handleExpand,
    handleClickShowAuth,
    onClickMenuItem,
    onClickLogout,
  };
};

export default useCustomHooks;
