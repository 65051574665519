import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import {
  AppTitle,
  ConfirmButton,
  Input,
  Select,
  SmText,
  Text,
} from "Components";
import { formatError } from "helpers/error";
import useAuth from "hooks/useAuth";
import useGetData from "hooks/useGetData";
import { useLoading } from "providers/loading";
import { useNotify } from "providers/notify";
import { ReactNode, memo, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getUser } from "redux/selectors/user";
import { apiGetCountries } from "services/api/helpers";
import { apiCreateSmartTradeAssist } from "services/api/request";
import { formatReset } from "./TradeAssist.helpers";

type Props = {
  item_code: string;
};

const TradeAssist = ({ item_code }: Props) => {
  const intl = useIntl();
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDialog = () => setShowDialog(false);

  const onOpenDialog = () => setShowDialog(true);
  return (
    <>
      <StyledSmText onClick={onOpenDialog} align="center">
        {intl.formatMessage({ id: "request_trade_assist" })}
      </StyledSmText>

      <Dialog
        open={showDialog}
        onClose={onCloseDialog}
        PaperProps={{
          style: {
            maxWidth: 640,
            width: "100%",
          },
        }}
      >
        <TradeAssistForm {...{ onCloseDialog, item_code }} />
      </Dialog>
    </>
  );
};

const TradeAssistForm = ({
  onCloseDialog,
  item_code,
}: {
  onCloseDialog: () => void;
  item_code: string;
}) => {
  const { setLoading } = useLoading();
  const { setNotify } = useNotify();
  const { isLoggedIn } = useAuth();
  const user = useSelector(getUser);
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmitForm = (data: any) => {
    setLoading(true);
    apiCreateSmartTradeAssist({ ...data, farm_item_code: item_code })
      .then((res) => {
        setNotify({
          open: true,
          type: "success",
          msg: intl.formatMessage({ id: "sent_successful" }),
        });
        onCloseDialog();
      })
      .catch((err) => {
        setNotify({
          open: true,
          type: "error",
          msg: formatError(err)?.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const { data: countryData } = useGetData({
    promise: apiGetCountries,
  });
  const countries = useMemo(() => countryData?.data || [], countryData?.data);
  const phoneCountryCode = watch("country") || "";

  const phoneCountryData = useMemo(
    () => countries.find((country: any) => country.name === phoneCountryCode),
    [phoneCountryCode, countries]
  );

  useEffect(() => {
    if (isLoggedIn) {
      reset(formatReset(user));
    }
  }, [isLoggedIn]);

  return (
    <StyledTradeAssistForm p={2} spacing={2}>
      <div>
        <Box textAlign="right">
          <IconButton onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>

        <AppTitle align="center">
          {intl.formatMessage({ id: "request_smart_trade_assist" })}
        </AppTitle>
      </div>

      <Stack direction="row" spacing={1} className="reference-text">
        <IoMdInformationCircleOutline />
        <SmText>
          {intl.formatMessage(
            { id: "trade_assist_desc" },
            { s: (code: ReactNode) => <span>{code}</span> }
          )}
        </SmText>
      </Stack>

      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmitForm)}>
        <Input
          {...register("first_name", {
            required: {
              value: true,
              message: intl.formatMessage({ id: "requied_input" }),
            },
          })}
          placeholder={"* " + intl.formatMessage({ id: "first_name" })}
          error={!!errors?.first_name}
          helperText={errors?.first_name?.message}
        />

        <Input
          {...register("last_name", {
            required: {
              value: true,
              message: intl.formatMessage({ id: "requied_input" }),
            },
          })}
          placeholder={"* " + intl.formatMessage({ id: "last_name" })}
          error={!!errors?.last_name}
          helperText={errors?.last_name?.message}
        />

        <Input
          {...register("email", {
            required: {
              value: true,
              message: intl.formatMessage({ id: "requied_input" }),
            },
          })}
          placeholder={"* " + intl.formatMessage({ id: "email_address" })}
          error={!!errors?.email}
          helperText={errors?.email?.message}
        />

        <FormControl
          error={!!errors?.country}
          className={phoneCountryCode ? "" : "placeholder"}
        >
          <Controller
            name="country"
            control={control}
            rules={{
              required: intl.formatMessage({ id: "requied_input" }),
            }}
            defaultValue={phoneCountryCode}
            render={({ field }) => (
              <Select {...field}>
                <MenuItem className="placeholder" disabled value="">
                  {"* " + intl.formatMessage({ id: "select_country" })}
                </MenuItem>
                {countries.map(({ country_name, name }: any) => (
                  <MenuItem key={name} value={name}>
                    <Text>{country_name}</Text>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>{errors?.country?.message}</FormHelperText>
        </FormControl>

        <Input
          {...register("phone_number", {
            required: {
              value: true,
              message: intl.formatMessage({ id: "requied_input" }),
            },
          })}
          placeholder={"* " + intl.formatMessage({ id: "phone_number" })}
          error={!!errors?.phone_number}
          helperText={errors?.phone_number?.message}
          InputProps={{
            startAdornment: phoneCountryCode && (
              <InputAdornment position="start">
                {phoneCountryCode.toUpperCase()} (+
                {phoneCountryData?.custom_calling_code})
              </InputAdornment>
            ),
          }}
        />

        <Input
          {...register("company_name", {
            required: {
              value: true,
              message: intl.formatMessage({ id: "requied_input" }),
            },
          })}
          placeholder={"* " + intl.formatMessage({ id: "company_name" })}
          error={!!errors?.company_name}
          helperText={errors?.company_name?.message}
        />

        <Input
          {...register("note")}
          placeholder={intl.formatMessage({ id: "note" })}
          multiline
          rows={3}
        />

        <ConfirmButton type="submit">
          {intl.formatMessage({ id: "submit" })}
        </ConfirmButton>
      </Stack>
    </StyledTradeAssistForm>
  );
};

const StyledSmText = styled(Typography)({
  fontSize: 14,
  color: "var(--gray)",
  textDecoration: "underline",
  cursor: "pointer",
});

const StyledTradeAssistForm = styled(Stack)({
  ".reference-text": {
    color: "#014361",
    backgroundColor: "#E5F6FD",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    svg: {
      minWidth: 22,
      color: "#03A9F4",
      marginRight: 4,
    },
    span: {
      cursor: "pointer",
      color: "var(--sub-color)",
    },
  },
});

export default memo(TradeAssist);
