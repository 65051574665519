import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";

import useStyles from "./styles";
import { LeftBox, RightBox } from "./components";

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.main}>
        <Helmet>
          <title>404 - Not found</title>
        </Helmet>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container className={classes.leftBox}>
              <LeftBox />
            </Grid>
            <Grid item xs={12} md={6}>
              <RightBox />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PageNotFound;
