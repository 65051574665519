import {
  TextFieldProps,
  TextField,
  styled,
  StackProps,
  Stack,
  SelectProps,
  Select as SelectLib,
  MenuItem,
  Autocomplete,
  Box,
  Dialog,
  LinearProgress,
  IconButton,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactNode, forwardRef, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { SmText, SmTextMd, XsText } from "./Text";
import { IoMdClose } from "react-icons/io";
import { useNotify } from "providers/notify";
import { formatError } from "helpers/error";
import { CheckIcon, DeleteIcon, RegenerateIcon } from "Assets/icons";
import Row from "./Row";
import { AI_PROCESSING_IMG } from "Assets/images";
import { Img } from "./Img";
import { BaseButton } from "./Button";
import Disclaimer from "./Disclaimer";
import { FiInfo } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setGeneratingAI } from "redux/actions/layout";
import { OptionType } from "types";

type FakeInputType = {
  error?: boolean;
};

export type InputProp = TextFieldProps & {
  toplabel?: string;
  requiredlabel?: boolean;
};

export const Input = forwardRef(
  (
    props: TextFieldProps & { toplabel?: string; requiredlabel?: boolean },
    ref: any
  ) => {
    const { requiredlabel } = props;
    if (!props.toplabel) return <BaseInput ref={ref} {...props} />;
    return (
      <Stack spacing={1}>
        <SmTextMd
          className={"input-label" + (requiredlabel ? " required" : "")}
        >
          {props.toplabel}
        </SmTextMd>
        <BaseInput ref={ref} autoComplete="off" {...props} />
      </Stack>
    );
  }
);

export const PasswordInput = forwardRef(
  (
    props: TextFieldProps & { toplabel?: string; requiredlabel?: boolean },
    ref: any
  ) => {
    const { requiredlabel } = props;
    const [showPw, setShowPw] = useState(false);

    const onClickShowPw = () => {
      setShowPw(!showPw);
    };

    const Common = () => (
      <BaseInput
        type={showPw ? "text" : "password"}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <IconButton onClick={onClickShowPw}>
              {showPw ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          ),
        }}
        ref={ref}
        {...props}
      />
    );

    if (!props.toplabel) return <Common />;
    return (
      <Stack spacing={1}>
        <SmTextMd
          className={"input-label" + (requiredlabel ? " required" : "")}
        >
          {props.toplabel}
        </SmTextMd>
        <Common />
      </Stack>
    );
  }
);

export const Select = forwardRef(
  (
    props: SelectProps & {
      options?: any[];
      toplabel?: string;
      requiredlabel?: boolean;
    },
    ref: any
  ) => {
    const intl = useIntl();
    const { options, toplabel, requiredlabel } = props;

    const finalOptions = useMemo(
      () => [
        {
          value: "",
          label: intl.formatMessage({ id: "please_select" }),
          disabled: true,
        },
        ...(options || []),
      ],
      [options]
    );
    const Common = () => (
      <BaseSelect
        ref={ref}
        displayEmpty
        defaultValue=""
        renderValue={
          options
            ? (value: any) => {
                const selectedOption = options.find(
                  (option) => option.value === value
                );

                return (
                  <>
                    {selectedOption?.label || (
                      <span className="placeholder">
                        {props.placeholder ||
                          intl.formatMessage({ id: "please_select" })}
                      </span>
                    )}
                  </>
                );
              }
            : undefined
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 360,
            },
          },
        }}
        {...props}
      >
        {props.options
          ? finalOptions?.map((option) => (
              <MenuItem
                key={option?.value}
                value={option?.value}
                disabled={option.disabled}
              >
                {option?.label}
              </MenuItem>
            ))
          : props.children}
      </BaseSelect>
    );
    if (!toplabel) return <Common />;

    return (
      <Stack spacing={1}>
        <SmTextMd
          className={"input-label" + (requiredlabel ? " required" : "")}
        >
          {toplabel}
        </SmTextMd>
        <Common />
      </Stack>
    );
  }
);

type AIGenerateInputProps = {
  useForm: any;
  fieldName: string;
  label?: string;
  requiredlabel?: boolean;
  children?: ReactNode;
  isValid?: boolean;
  apiGenerate?: any;
  onClickGenerate?: () => void;
  onClickApplyBonus?: () => void;
};

export const AIGenerateInput = ({
  useForm,
  fieldName,
  label,
  requiredlabel,
  children,
  isValid,
  apiGenerate,
  onClickGenerate = () => {},
  onClickApplyBonus = () => {},
}: AIGenerateInputProps) => {
  const intl = useIntl();
  const { setNotify } = useNotify();
  const dispatch = useDispatch();

  const { getValues, setValue } = useForm;
  const [isGenerating, setIsGenerating] = useState(false);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [progress, setProgress] = useState(78);
  const [tmpValue, setTmpValue] = useState<string>("");
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onClickDiscard = () => {
    setOpenGenerate(false);
    dispatch(setGeneratingAI(false));
  };

  const onClickApply = () => {
    setValue(fieldName, (getValues(fieldName) || "") + "\n" + tmpValue, {
      shouldValidate: true,
    });
    // setValue(fieldName, tmpValue, {
    //   shouldValidate: true,
    // });
    onClickDiscard();
    onClickApplyBonus?.();
  };

  const onClickAction = (action: string) => {
    switch (action) {
      case "apply":
        onClickApply();
        break;
      case "regenerate":
        onGenerateRequest();
        break;

      case "discard":
        onClickDiscard();
        break;
      default:
        break;
    }
  };

  const onGenerateRequest = () => {
    onClickGenerate?.();
    if (isValid && apiGenerate) {
      dispatch(setGeneratingAI(true));
      setIsGenerating(true);
      setProgress(0);
      setOpenGenerate(true);
      apiGenerate()
        .then((res: any) => {
          setTmpValue(res.data.message?.data);
          setProgress(100);
        })
        .catch((err: any) => {
          console.log(err);
          setOpenGenerate(false);
          setNotify({
            open: true,
            msg: formatError(err).message,
            type: "error",
          });
        })
        .finally(() => {
          setIsGenerating(false);
        });
    }
  };
  const actions = [
    {
      label: intl.formatMessage({ id: "apply" }),
      icon: <CheckIcon />,
      value: "apply",
    },
    {
      label: intl.formatMessage({ id: "regenerate" }),
      icon: <RegenerateIcon />,
      value: "regenerate",
    },
    {
      label: intl.formatMessage({ id: "discard" }),
      icon: <DeleteIcon />,
      value: "discard",
    },
  ];
  useEffect(() => {
    if (progress < 100) {
      const autoCountProgress = setInterval(() => {
        setProgress((progress) => Math.min(progress + 10, 90));
      }, 1000);

      return () => clearInterval(autoCountProgress);
    }
  }, [isGenerating]);

  const onClickInfoIcon = () => {
    setShowDisclaimer(true);
  };

  const onCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <Stack spacing={1} className="input">
      <Row justifyContent="space-between">
        <SmTextMd
          className={"input-label" + (requiredlabel ? " required" : "")}
        >
          {label}
        </SmTextMd>
        <GenerateButton
          startIcon={<Img src={AI_PROCESSING_IMG} alt="img" />}
          onClick={onGenerateRequest}
        >
          {intl.formatMessage({
            id: isGenerating ? "generating" : "generate_request",
          })}
        </GenerateButton>
      </Row>

      <div style={{ display: openGenerate ? "none" : "block" }}>{children}</div>

      {openGenerate && (
        <StyledAIGenerateInput
          justifyContent={isGenerating ? "center" : "flex-start"}
        >
          <Box
            className="generate-box"
            alignItems={"center"}
            height="calc(100% - 36px)"
          >
            {progress === 100 && (
              <Stack className="generate-detail">
                <SmText className="generate-text">{tmpValue}</SmText>

                <Box id="alert">
                  <Row p={2}>
                    <FiInfo onClick={onClickInfoIcon} cursor="pointer" />
                    <SmText>{intl.formatMessage({ id: "disclaimer" })}</SmText>
                  </Row>
                  <Stack className="actions">
                    {actions.map((action, id) => (
                      <MenuItem
                        onClick={() => onClickAction(action.value)}
                        key={id}
                      >
                        <Row>
                          {action.icon}
                          <SmTextMd>{action.label}</SmTextMd>
                        </Row>
                      </MenuItem>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            )}
          </Box>
          {isGenerating && (
            <Row justifyContent="center" width="100%">
              <LinearProgress
                variant="determinate"
                className="progress"
                value={progress}
              />
              <SmTextMd>{progress}%</SmTextMd>
            </Row>
          )}
        </StyledAIGenerateInput>
      )}

      <Dialog
        open={showDisclaimer}
        onClose={onCloseDisclaimer}
        PaperProps={{
          sx: {
            maxWidth: 544,
            width: "100%",
          },
        }}
      >
        <Disclaimer onClose={onCloseDisclaimer} />
      </Dialog>
    </Stack>
  );
};

const GenerateButton = styled(BaseButton)({
  float: "right",
  border: "1px solid var(--gray)",
  borderRadius: 16,
  width: "fit-content",
  padding: "3px 12px",
  lineHeight: "18px",
  "&,:hover": { backgroundColor: "#fff" },
  img: {
    height: 20,
  },
  svg: {
    marginLeft: 8,
  },
});

export const BelowSelect = (
  props: SelectProps & {
    toplabel?: string;
    customValue?: any[];
    options?: any[];
    showBelow?: boolean;
    onDeleteSelect?: (value: string | number) => void;
    requiredlabel?: boolean;
  }
) => {
  const {
    placeholder,
    showBelow = true,
    customValue,
    options,
    onDeleteSelect = () => {},
    toplabel,
    requiredlabel,
  } = props;

  const Common = () => (
    <BaseSelect
      displayEmpty
      renderValue={() => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span className="placeholder">{placeholder}</span>
        </div>
      )}
      defaultValue={[]}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 360,
          },
        },
      }}
      {...props}
    >
      {options?.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option?.label}
        </MenuItem>
      ))}
    </BaseSelect>
  );

  return (
    <Stack spacing={1}>
      {!toplabel && <Common />}

      {toplabel && (
        <>
          <SmTextMd
            className={"input-label " + (requiredlabel ? " required" : "")}
          >
            {toplabel}
          </SmTextMd>
          <Common />
        </>
      )}

      {showBelow && (
        <BelowChips {...{ options, customValue, onDeleteSelect }} />
      )}
    </Stack>
  );
};

export const BelowChips = ({
  customValue = [],
  options,
  onDeleteSelect,
}: {
  customValue?: any[];
  options?: OptionType[];
  onDeleteSelect?: (value: string | number) => void;
}) => {
  if (customValue?.length < 1) return null;
  return (
    <Stack direction="row" flexWrap="wrap" gap={0.5}>
      {(customValue || []).map((v: any) => {
        const selectedLabel =
          typeof v === "string"
            ? options?.find((o) => o.value === v)?.label
            : v?.label;

        return (
          <SelectChip key={v}>
            <XsText>{selectedLabel}</XsText>
            <IoMdClose onClick={() => onDeleteSelect?.(v)} />
          </SelectChip>
        );
      })}
    </Stack>
  );
};

export const RoundedInput = forwardRef((props: TextFieldProps, ref: any) => {
  return (
    <StyledRoundedInput
      ref={ref}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
});

export const FakeInput = (props: StackProps & FakeInputType) => {
  return <StyledFakeInput {...props} error={props.error} />;
};

export const BaseInput = styled(TextField)({
  input: {
    padding: "10px 14px",
  },
  ".MuiInputBase-root": {
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 8,
  },
});

export const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  "input.MuiAutocomplete-input": {
    padding: "0 !important",
  },
});

StyledAutocomplete.defaultProps = {
  noOptionsText: null,
};

export const BaseSelect = styled(SelectLib)({
  ".MuiSelect-select": {
    padding: "10px 14px",
    backgroundColor: "#fff",
  },
  ".MuiInputBase-root": {},
  borderRadius: 8,
});

const StyledRoundedInput = styled(TextField)({
  // input: {
  //   padding: "10px 14px",
  // },
});

const StyledAIGenerateInput = styled(FakeInput)({
  flex: 1,
  minHeight: 48,
  backgroundColor: "#fff",
  ".input": {
    width: "100%",
  },
  ".progress": {
    width: "100%",
    maxWidth: 278,
    borderRadius: 4,
    height: 8,
    ".MuiLinearProgress-bar": {
      backgroundColor: "#008934",
      borderRadius: 4,
    },
  },
  ".generate-box": {
    ".generate-detail": {
      width: "100%",
      minHeight: "100%",
    },
    ".generate-detail, .actions": {
      zIndex: 1,
      whiteSpace: "pre-line",
    },
    ".generate-text": {
      padding: "16px",
      backgroundColor: "rgb(229, 246, 253)",
      maxHeight: 300,
      overflow: "hidden",
      overflowY: "scroll",
    },
    display: "flex",

    "#alert": {
      position: "relative",
      ".actions": {
        padding: "16px",
        width: "100%",
        maxWidth: 260,
        marginTop: 8,
        border: "1px solid #F2F4F7",
        borderRadius: 4,
      },
    },
  },
});

const SelectChip = styled("div")({
  borderRadius: 8,
  padding: 8,
  border: "1px solid #E0E0E0",
  display: "flex",
  gap: 8,
  alignItems: "center",
  svg: {
    cursor: "pointer",
  },
});

const StyledFakeInput = styled(Stack)<{ error?: boolean }>(({ error }) => ({
  padding: "9px 14px",
  border: `1px solid ${error ? "#f03e3e !important" : "#D0D5DD"}`,
  borderRadius: 8,
  ":hover": {
    borderColor: "#000",
  },
  ".hidden": {
    display: "none",
  },
  cursor: "pointer",
}));
