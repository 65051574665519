import { useState, useEffect, createContext, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FarmItemInformation } from "./components";
import { setselectedItem } from "../../Actions/cartActions";
import { setFarm } from "../../Actions/commonActions";
import { apiGetItemDetail } from "services/api/item";
import { useCommonData } from "providers/common-data";
import { LoadingSkeleton } from "Components";
import { apiGetItemPosts } from "services/api/smartTrace";
import useGetData from "hooks/useGetData";
import { PATHS } from "constants/routes";

const ItemContext = createContext();
export const useItemContext = () => useContext(ItemContext);

const LIMIT_POST = 10;

const FarmItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId } = useParams();
  const { onChangeBreadscrumbs } = useCommonData();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterPost, setFilterPost] = useState({
    offset: 0,
    limit: LIMIT_POST,
  });

  const { data: postData } = useGetData({
    promise: () => apiGetItemPosts({ ...filterPost, farm_item: itemId }),
    deps: [itemId, filterPost],
  });

  useEffect(() => {
    setLoading(true);
    apiGetItemDetail({ farm_item: itemId })
      .then((res) => {
        let resData = res?.data?.message?.data;
        let itemData = resData?.farm_item_data || {};
        let companyData = resData?.farm || {};
        localStorage.setItem("from_page", "farm_web");
        setData({
          ...itemData,
          farm_avatar: companyData?.farm_avatar,
          identity: companyData?.identity,
        });
        setLoading(false);
        localStorage.setItem("last_farm_id", companyData?.farm_id);
        dispatch(
          setselectedItem({
            name: itemData?.name,
            farm_item_name: itemData?.farm_item_name,
            farm_item_image: itemData?.farm_item_image,
            attributes: itemData?.farm_item_offer?.offer_price,
          })
        );
        dispatch(
          setFarm({
            farmName: companyData?.farm_name,
            farmImage: companyData?.farm_avatar,
            name: itemId,
            ...companyData,
          })
        );
        onChangeBreadscrumbs(itemData?.breadscrumb || []);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        localStorage.setItem("pre_page", window.location.pathname);
        history.replace(PATHS.notFound);
      });
  }, [itemId]);

  const contextData = {
    data,
    filterPost,
    postData: postData?.data,
    setFilterPost,
  };

  return (
    <ItemContext.Provider value={contextData}>
      {loading && <LoadingSkeleton count={3} />}

      {data && !loading && <FarmItemInformation />}
    </ItemContext.Provider>
  );
};

export default FarmItem;
