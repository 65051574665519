import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { withProfile } from "hocs/withProfile";
import {
  Category,
  Login,
  QuickRFQ,
  ResetPassword,
  Sitemap,
  Register,
  UserProfile,
  SendInquiry,
  ActiveAccount,
  Products,
  Suppliers,
  Payment,
  ActiveAccountBuyer,
  Unsubscribe,
} from "Pages";
import { Layout, Wait } from "Components";
import { useSelector } from "react-redux";
import { PATHS } from "constants/routes";
import SetNewPassword from "Pages/reset-password/components/SetNewPassword";
import useTwilio from "hooks/useTwilio";
import AuthLayout from "layouts/auth-layout";

const TopCompanies = lazy(() => import("Pages/TopCompanies"));
const AllProducts = lazy(() => import("Pages/AllProducts"));
const Home = lazy(() => import("Pages/home"));
const Search = lazy(() => import("Pages/Search"));
const TopProducts = lazy(() => import("Pages/TopProducts"));
const Farm = lazy(() => import("./Pages/Farm"));
const FarmItem = lazy(() => import("./Pages/FarmItem"));
const PageNotFound = lazy(() =>
  import("./Pages/PageNotFound" /* webpackChunkName: "pagenotpage" */)
);

const AppRouter = () => {
  const waiting = useSelector((state) => state?.commonReducer?.waiting);

  useTwilio();

  return (
    <Switch>
      <Route path={PATHS.paymentBuyer} component={Payment} />
      <Route path={PATHS.paymentSupplier} component={Payment} />
      <Route path={PATHS.user}>
        <Layout hideSearch>
          <UserProfile />
        </Layout>
      </Route>

      {/* auth layout */}
      <Route path={PATHS.register}>
        <AuthLayout children={<Register />} />
      </Route>
      <Route path={PATHS.login} exact>
        <AuthLayout children={<Login />} />
      </Route>
      <Route path={PATHS.resetPassword}>
        <AuthLayout children={<ResetPassword />} />
      </Route>
      <Route path={PATHS.updatePassword}>
        <AuthLayout children={<SetNewPassword />} />
      </Route>

      {/* user layout */}
      <Route>
        <Layout>
          <Switch>
            <Route path={PATHS.notFound} component={PageNotFound} />
            <Route path="/" exact component={Home} />
            <Route path={PATHS.sitemap} component={Sitemap} />
            <Route path="/quick-rfq" component={QuickRFQ} />
            <Route path={`${PATHS.supplier}/:supplierId`} component={Farm} />
            <Route path={`${PATHS.item}/:itemId`} component={FarmItem} />
            <Route path={PATHS.search} component={Search} />
            <Route path={PATHS.searchSuppliers} component={Search} />
            <Route
              path={`${PATHS.category}/:categoryId`}
              component={Category}
            />
            <Route path={PATHS.sendInquiry} component={SendInquiry} />
            <Route
              path={`${PATHS.categorySupplier}/:categoryId`}
              component={Category}
            />
            <Route path="/vietnam-product/:categoryId" component={Category} />
            <Route path="/vietnam-supplier/:categoryId" component={Category} />
            <Route path={PATHS.activeAccount} component={ActiveAccount} />
            <Route path={PATHS.products} component={Products} />
            <Route path={PATHS.suppliers} component={Suppliers} />
            <Route path={PATHS.topItems} component={TopProducts} />
            <Route path={PATHS.farms} component={TopCompanies} />
            <Route path={PATHS.allItems} component={AllProducts} />
            <Route
              path={PATHS.activeAccountBuyer}
              component={ActiveAccountBuyer}
            />
            <Route path={PATHS.unsubscribe} component={Unsubscribe} />
            <Route path="*" component={() => <Redirect to="404" />} />
          </Switch>
        </Layout>

        <Wait open={waiting} />
      </Route>
    </Switch>
  );
};

export default withProfile(AppRouter);
