
export const selectItem = (item_id) => {
  return {
    type: "SELECT_ITEM",
    payload: item_id
  }
}

export const changePoint = (point) => {
  return {
    type: "CHANGE_POINT",
    payload: point
  }
}

export const setShowCart = (showCart) => {
  return {
    type: "SET_SHOW_CART",
    payload: showCart
  }
}

export const removeItemVer2 = (data) => {
  return {
    type: "REMOVE_ITEM_VER2",
    payload: data
  }
}

export const setselectedItem = (item) => {
  return {
    type: "SET_SELECTED_ITEM",
    payload: item
  }
}

export const pushVer2 = (data) => {
  return {
    type: "PUSH_VER2",
    payload: data
  }
}

export const replaceCartVer2 = (data) => {
  return {
    type: "REPLACE_CART_VER2",
    payload: data
  }
}

export const updateSameItemVer2 = (data) => {
  return {
    type: "UPDATE_SAME_ITEM",
    payload: data
  }
}

export const update = (data) => {
  return {
    type: "UPDATE",
    payload: data
  }
}

export const descQltVer2 = (data) => {
  return {
    type: "DESC_QTY_VER2",
    payload: data
  }
}

export const insQltVer2 = (data) => {
  return {
    type: "INSC_QTY_VER2",
    payload: data
  }
}

export const setQuantity = (data) => {
  return {
    type: "SET_QTY",
    payload: data
  }
}

export const changeNoteVer2 = (data) => {
  return {
    type: "CHANGE_NOTE_VER_2",
    payload: data
  }
}

export const resetCart = () => {
  return {
    type: "RESET_CART"
  }
}