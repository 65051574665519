import http from "services/http";
import { FarmPostsType, ItemPostsType } from "types/params";

const PRE_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetFarmPosts = (data: FarmPostsType) =>
  http.get(`${PRE_ENDPOINT}.farm.get_posts`, { params: data });

export const apiGetItemPosts = (data: ItemPostsType) =>
  http.get(`${PRE_ENDPOINT}.farm_item.get_posts`, { params: data });
