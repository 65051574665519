// import React from "react";
import { Avatar, Breadcrumbs, Link, Stack } from "@mui/material";
// import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";

// import { formatImgUrl } from "../../utils/helpers";
import { styled } from "@mui/system";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export default function CustomBreadcrumb({ links = [] }) {
  const intl = useIntl();
  //   const company = useSelector((state) => state?.commonReducer?.farm);
  //   const history = useHistory();

  const paths = useMemo(
    () => [{ label: intl.formatMessage({ id: "home" }), value: "/" }, ...links],
    [links]
  );

  return (
    <Stack flexDirection="col" alignItems="left">
      <CustomBreadcrumbs aria-label="breadcrumb">
        {paths?.map((link, index) => (
          <CustomLink variant="body2" href={`${link?.value}`} key={index}>
            {link?.label}
          </CustomLink>
        ))}
      </CustomBreadcrumbs>
    </Stack>
  );
}

const CustomLink = styled(Link)({
  textDecorationLine: "initial",
  color: "#828282",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 100,
  whiteSpace: "nowrap",
});

const CustomBreadcrumbs = styled(Breadcrumbs)({
  "& .MuiBreadcrumbs-separator": {
    margin: "0 4px",
  },
});
