import { Stack, Tab, styled } from "@mui/material";
import { Tabs } from "Components";
import { SyntheticEvent, lazy, memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { withLoading } from "hocs/withLoading";
import { useItemContext } from "..";
import useNavigate from "hooks/useNavigate";
import { objectToQueryString } from "helpers/search";
import { SEARCH_PARAMS } from "constants/routes";
const Posts = withLoading(lazy(() => import("./Posts")));
const ItemDetails = withLoading(lazy(() => import("./ItemDetails")));

const ItemTabs = () => {
  const intl = useIntl();
  const { postData } = useItemContext();
  const { history, useSearchParams } = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    useSearchParams.get(SEARCH_PARAMS.tab) || ""
  );
  const tabs = [
    { label: intl.formatMessage({ id: "product_details" }), value: "" },
    {
      label: intl.formatMessage({ id: "post" }),
      value: "post",
      hide: (postData?.data || []).length < 1,
    },
  ];

  const onChangeTab = (event: SyntheticEvent, value: string) => {
    setSelectedTab(value);
    history.replace({
      search: objectToQueryString({ [SEARCH_PARAMS.tab]: value }),
    });
  };

  const ContentTab = useMemo(() => {
    switch (selectedTab) {
      case "post":
        return Posts;

      default:
        return ItemDetails;
    }
  }, [selectedTab]);

  return (
    <StyledItemTabs spacing={2}>
      <Tabs value={selectedTab} onChange={onChangeTab}>
        {tabs
          .filter((tab) => !tab.hide)
          .map(({ label, value }) => (
            <Tab key={value} {...{ label, value }} />
          ))}
      </Tabs>

      <ContentTab />
    </StyledItemTabs>
  );
};

const StyledItemTabs = styled(Stack)({
  button: {
    "&.unselected": {
      backgroundColor: "transparent",
      color: "#000",
    },
  },
});

export default memo(ItemTabs);
