import { Stack, styled } from "@mui/material";
import {
  Categories,
  HtmlViewer,
  LoadingSkeleton,
  ReportButton,
  TextBold,
} from "Components";
import useGetData from "hooks/useGetData";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { apiGetCompanyOverview } from "services/api/company";
import SupplierMedias from "./SupplierMedias";
import SupplierAttributes from "./SupplierAttributes";
import SupplierPosts from "./SupplierPosts";
import { CategoryType } from "types";
import { useParams } from "react-router-dom";
import { ExportCountries } from ".";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { REPORT_TYPE } from "constants/data";

const OverviewTab = () => {
  const intl = useIntl();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();

  const { data, isLoading } = useGetData({
    promise: () => {
      if (supplierId) {
        return apiGetCompanyOverview({ farm_id: supplierId });
      }
    },
    deps: [supplierId],
  });
  const overviewData = useMemo(
    () => data?.data?.farm || {},
    [data?.data?.farm]
  );
  const {
    farm_description,
    farm_media = [],
    attr = [],
    posts = [],
    categories = [],
    export_countries = [],
    farm_name,
  } = overviewData;

  return (
    <StyledOverviewTab spacing={2} my={2}>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && (
        <>
          {attr.length > 0 && <SupplierAttributes attr={attr} />}
          {farm_description && (
            <>
              <TextBold component="h2">
                {intl.formatMessage({ id: "introduction" })}
              </TextBold>
              <HtmlViewer className="description" content={farm_description} />
            </>
          )}
          {farm_media.length > 0 && <SupplierMedias medias={farm_media} />}
          <SupplierPosts {...{ posts }} />
          {categories.length > 0 && (
            <Categories
              boxTitle="following_categories"
              defaultCategories={categories.map((category: CategoryType) => ({
                ...category,
                name: category?.category_name,
              }))}
            />
          )}
          {export_countries.length > 0 && (
            <ExportCountries exportCountries={export_countries} />
          )}
          {isMobile && (
            <ReportButton
              type={REPORT_TYPE.supplier}
              title={farm_name}
              mobile
            />
          )}
        </>
      )}
    </StyledOverviewTab>
  );
};

const StyledOverviewTab = styled(Stack)({
  ".description": {
    color: "var(--gray-color)",
    fontSize: 14,
  },
});

export default memo(OverviewTab);
