import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { LgTextSemiBold, Row, SmText } from "Components";
import React, { memo } from "react";
import { useIntl } from "react-intl";
import { OptionType } from "types";

type Props = {
  attr: any[];
};

const SupplierAttributes = ({ attr = [] }: Props) => {
  const i = useIntl();

  return (
    <Stack spacing={2}>
      <LgTextSemiBold component="h2">
        {i.formatMessage({ id: "general_information" })}
      </LgTextSemiBold>

      <StyledTableInformations spacing={1}>
        <div>
          <Stack className={`data_table`}>
            {attr.map((data: OptionType, i: number) => (
              <Row key={i} p={2}>
                <SmText className="label">{data.key}</SmText>
                <SmText className="value">{data.value}</SmText>
              </Row>
            ))}
          </Stack>
        </div>
      </StyledTableInformations>
    </Stack>
  );
};

const StyledTableInformations = styled(Stack)({
  width: "100%",
  ".data_table": {
    ".label, .value": {
      flex: 1,
    },
    ".label": {
      fontWeight: 700,
      color: "#667085",
    },
    ".value": {
      color: "#667085",
    },
    "&>:nth-child(odd)": {
      backgroundColor: "#F2F4F7",
    },
  },
});

export default memo(SupplierAttributes);
