import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  main: {
    position: "relative",
    textAlign: "center",
    "& .mainImg": {
      maxWidth: "100%"
    },
    "& .bgImg": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%"
    }
  }
}));
