import { Grid, Box, styled, Stack } from "@mui/material";

import { CustomBreadcrumb, RelatedSearches } from "Components";
import { useMemo } from "react";
import { ContactSupplierCard } from "../ContactSupplier";
import { AboutSupplier } from "../Supplier";
import { useItemContext } from "Pages/FarmItem";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { ItemBanners, BottomItems, SuggestedItems, MainInformations } from "..";
import ItemTabs from "../ItemTabs";

export default function FarmItemInformation(props) {
  const { data } = useItemContext();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();

  const images = useMemo(() => {
    return [
      ...[data?.farm_item_image ? { url: data?.farm_item_image } : []],
      ...(data?.farm_item_media || []),
    ];
  }, [data?.farm_item_image, data?.farm_item_media]);

  return (
    <StyledContainer {...props}>
      <Box mb={3}>
        <CustomBreadcrumb links={data?.breadscrumb} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={(7 * 6) / 5} maxWidth="100%" bgcolor="#fff">
          <Grid container spacing={2}>
            <Grid item xs={12} md={(2 / 5) * 12}>
              <ItemBanners images={images} className="banners" />
            </Grid>
            <Grid item xs={12} md={(3 / 5) * 12}>
              <MainInformations
                className={`main_infors ${isMobile ? "" : "border"}`}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={2} p={2}>
                <ItemTabs />
                {!isMobile && <AboutSupplier />}
                <BottomItems />
                <RelatedSearches searches={data.tags} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid item md={(3 * 6) / 5}>
            <Box position="sticky" top={154}>
              <Stack spacing={2} className="border">
                <ContactSupplierCard className="contact_suppliers" />
              </Stack>
              <SuggestedItems className="border" mt={2} />
            </Box>
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)({
  backgroundColor: "#F9FAFB",
  ".border": {
    borderRadius: 4,
    padding: 16,
    backgroundColor: "#fff",
  },
  ".banners, .main_infors": {
    height: "fit-content",
  },
});
