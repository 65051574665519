import { PLAN_NAMES, SUPPLIER_PLANS } from "constants/data";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux/selectors/user";
import { UserPlan } from "types/common";

const FREE_PACKAGE = "Free";

const useUser = () => {
  const user = useSelector(getUser) || {};
  const { plan = {} } = user;

  const now = dayjs();

  const currentPlan: UserPlan = useMemo(() => {
    return {
      ...(SUPPLIER_PLANS.find((p) => p.label === plan?.plan_name) ||
        SUPPLIER_PLANS.find((p) => p.label === PLAN_NAMES.free)),
      ...plan,
      isExpired: dayjs(plan.end_date).isBefore(now),
    };
  }, [plan, now]);

  const isFreePlan = useMemo(
    () =>
      !plan.is_valid ||
      !plan.plan_name ||
      plan.plan_name === FREE_PACKAGE ||
      dayjs(plan.end_date).isBefore(now),
    [plan, now]
  );

  const canViewAnalytic = useMemo(
    () => true,
    // CAN_VIEW_ANALYTIC_PLANS.indexOf(currentPlan.label) > -1 && !isFreePlan,
    [currentPlan, isFreePlan]
  );

  return useMemo(
    () => ({
      user,
      isLoggedIn: user.is_logged_in,
      isSupplier: user.is_supplier === 1,
      currentPlan,
      isFreePlan,
      canViewAnalytic,
    }),
    [user, currentPlan, isFreePlan, canViewAnalytic]
  );
};

export default useUser;
