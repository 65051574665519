import { Stack, StackProps, styled } from "@mui/material";
import {
  SendInquiryButton,
  SendMessageButton,
  TextBold,
} from "Components";
import { memo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSupplierContext } from "..";

const ContactSupplier = (props: StackProps) => {
  const intl = useIntl();
  const { supplierId } = useParams<{ supplierId: string }>();
  const {
    supplierDetail: { identity },
  } = useSupplierContext();

  return (
    <StyledContactSupplier spacing={2} {...props}>
      <TextBold>{intl.formatMessage({ id: "contact_supplier" })}</TextBold>

      <Stack spacing={2} className="actions">
        <SendInquiryButton
          className="send-inq-btn"
          data={{ farm_id: supplierId }}
        />
        <SendMessageButton identity={identity} />
      </Stack>
    </StyledContactSupplier>
  );
};

const StyledContactSupplier = styled(Stack)({
  fontSize: 16,
  ".get_card_button": {
    "&.Mui-disabled": {
      opacity: 0.6,
    },
  },
  ".actions > *": {
    flex: 1,
    padding: "6px 12px",
  },
  ".send-inq-btn": {
    minHeight: 48,
  },
});

export default memo(ContactSupplier);
