import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web";

export const apiGetItemDetail = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.farm_item.get_farm_item_details`, params);

export const apiGetSidebarItems = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.farm_item.get_right_side_items`, params);

export const apiGetBottomItems = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.farm_item.get_bottom_items`, params);

export const apiGetAllProducts = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v2.listing.get_items`, params);

export const apiGetProductsByCategory = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.listing.get_items_by_cate`, params);

export const apiGetTopProducts = (limit = 5, page = 1) =>
  http.get(
    `${PRE_ENDPOINT}.v2.listing.get_top_items?limit=${limit}&offset=${
      page * limit
    }`
  );

export const apiGetProductsBySearch = (data = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.farm.get_items_by_search`, data);

export const apiGetRecommendProducts = (farm_item?: string, farm?: string) =>
  http.get(`${PRE_ENDPOINT}.v3.farm_item.get_recommended_items_by_item`, {
    params: {
      farm_item,
      farm,
    },
  });
