export const setFloorTable = (payload) => {
  return {
    type: "SET_FLOOR_TABLE",
    payload: payload
  }
}

export const setEarnPoint = (payload) => {
  return {
    type: "SET_EARN_POINT",
    payload: payload
  }
}

export const setFarm = (payload) => {
  return {
    type: "SET_FARM",
    payload: payload
  }
}

export const setOrderId = (orderId) => {
  return {
    type: "SET_ORDERID",
    payload: orderId
  }
};

export const refreshFarm = () => {
  return {
    type: "REFRESH_FARM"
  }
};

export const toggerWaiting = (data) => {
  return {
    type: "TOGGER_WAITING",
    payload: data
  }
};

export const changeUrlParams = (data) => {
  return {
    type: "SET_URL_PARAMS",
    payload: data
  }
};