import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  notfoundTitle: {
    fontWeight: "600 !important",
    fontSize: "140px !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "100px !important",
    },
  },
  notfoundSubTitle: {
    fontWeight: "600 !important",
    color: "var(--green-text-color)",
    fontSize: "26px !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px !important",
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      background: "var(--yellow-bg-color) !important",
      padding: "12px !important",
      minWidth: "180px !important",
      fontWeight: "400",
      color: "#fff",
      borderRadius: 32,
      textTransform: "initial",
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        minWidth: "150px !important",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    "& .left": {},
    "& .right": {
      marginTop: 0,
      [theme.breakpoints.down("md")]: {
        marginTop: 8,
      },
    },
    "& .tmp": {
      width: 32,
    },
  },
}));
