import { Box, styled } from "@mui/material";
import {
  SUPPLIER_BG_EN_DESKTOP,
  SUPPLIER_BG_EN_MOBILE,
  SUPPLIER_BG_VN_DESKTOP,
  SUPPLIER_BG_VN_MOBILE,
} from "Assets/images";
import { Img } from "Components";
import { useLanguageContext } from "Components/LanguageProvider";
import { COUNTRY_CODES } from "constants/data";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { memo, useMemo } from "react";
import { formatImgUrl } from "utils/helpers";

type Props = {
  wallpaper?: string;
};

const SupplierWallpaper = ({ wallpaper }: Props) => {
  const { isMobile } = useMediaBreakpoints();
  const { language } = useLanguageContext();

  const defaultBg = useMemo(
    () =>
      isMobile
        ? language === COUNTRY_CODES.vi
          ? SUPPLIER_BG_VN_MOBILE
          : SUPPLIER_BG_EN_MOBILE
        : language === COUNTRY_CODES.vi
        ? SUPPLIER_BG_VN_DESKTOP
        : SUPPLIER_BG_EN_DESKTOP,
    [isMobile, language]
  );
  return (
    <StyledSupplierWallpaper>
      <Img
        width="100%"
        src={wallpaper ? formatImgUrl(wallpaper) : defaultBg}
      />
    </StyledSupplierWallpaper>
  );
};

const StyledSupplierWallpaper = styled(Box)(({ theme }) => ({
  img: {
    objectFit: "cover",
    height: 360,
  },
  [theme.breakpoints.down("md")]: {
    img: {
      height: "auto",
      objectFit: "contain",
    },
  },
}));

export default memo(SupplierWallpaper);
