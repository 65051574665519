import OTPInput from "otp-input-react";

const CustomOTPInput = (props) => {
  return (
    <OTPInput
      autoFocus
      OTPLength={6}
      otpType="number"
      inputStyles={{
        width: 43,
        height: 48,
        marginRight: 5,
        border: "0.5px solid #BDBDBD",
      }}
      {...props}
    />
  );
};

export default CustomOTPInput;
