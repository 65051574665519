import { styled } from "@mui/material";
import {EN_GLOBAL_BG} from "Assets/images";
import React from "react";

type Props = {};

const LeftBanner = (props: Props) => {
  return <StyledDiv />;
};

const StyledDiv = styled("div")({
  width: "calc(50% - 48px)",
  height: "100%",
  background: `url('${EN_GLOBAL_BG}') center/cover no-repeat`,
});

export default LeftBanner;
