import { SVGProps } from "react";

export function CheckIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  stroke = "#344054",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function LgCheckIcon({
  width = 24,
  height = 25,
  viewBox = "0 0 24 25",
  stroke = "#027B30",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M20 6.6792L9 17.6792L4 12.6792"
        stroke="#027B30"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmoothCheckIcon({
  width = 19,
  height = 17,
  viewBox = "0 0 19 17",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6241 1.03115L5.88111 11.3991L3.03032 8.3532C2.50517 7.85806 1.67994 7.82805 1.07977 8.24817C0.494607 8.68329 0.329561 9.44851 0.689661 10.0637L4.0656 15.5552C4.3957 16.0654 4.96586 16.3804 5.61104 16.3804C6.22621 16.3804 6.81137 16.0654 7.14146 15.5552C7.68162 14.85 17.9895 2.56158 17.9895 2.56158C19.3399 1.1812 17.7044 -0.0341425 16.6241 1.01615V1.03115Z"
        fill="#019c3c"
      />
    </svg>
  );
}

export function CircleCheckIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#344054",
  stroke = "#344054",
  strokeWidth = 2,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986M22 3.99986L12 14.0099L9.00001 11.0099"
        stroke={color || stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThinCircleCheckIcon({
  width = 51,
  height = 50,
  viewBox = "0 0 51 50",
  stroke = "#019C3C",
  strokeWidth = 3.3,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M46.1628 23.0833V25C46.1602 29.4925 44.7055 33.8639 42.0155 37.4621C39.3256 41.0603 35.5446 43.6926 31.2364 44.9664C26.9282 46.2402 22.3237 46.0873 18.1096 44.5304C13.8954 42.9735 10.2975 40.096 7.85226 36.3272C5.40707 32.5584 4.24566 28.1001 4.54125 23.6173C4.83685 19.1345 6.57361 14.8673 9.49251 11.4522C12.4114 8.03711 16.3561 5.65703 20.7382 4.66695C25.1202 3.67688 29.705 4.12985 33.8086 5.95832M46.1628 8.33332L25.3294 29.1875L19.0794 22.9375"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MarkCheckIcon({
  width = 27,
  height = 16,
  viewBox = "0 0 27 16",
  stroke = "#101828",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M22.333 8L14.333 8M24.333 12L12.333 12M20.333 4L16.333 4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3337 4L6.00032 11.3333L2.66699 8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BoldCheckIcon({
  width = 10,
  height = 10,
  viewBox = "0 0 10 10",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.95534 0.728697L2.98867 6.48703L1.40534 4.79536C1.11367 4.52036 0.655338 4.5037 0.322005 4.73703C-0.0029951 4.9787 -0.094662 5.4037 0.105338 5.74536L1.98034 8.79536C2.16367 9.0787 2.48034 9.2537 2.83867 9.2537C3.18034 9.2537 3.50534 9.0787 3.68867 8.79536C3.98867 8.4037 9.71367 1.5787 9.71367 1.5787C10.4637 0.81203 9.55534 0.137031 8.95534 0.720364V0.728697Z"
        fill={color}
      />
    </svg>
  );
}

export function DubbleCheckIcon({
  width = 16,
  height = 10,
  viewBox = "0 0 16 10",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95534 0.851744L2.98867 6.61008L1.40534 4.91841C1.11367 4.64341 0.655338 4.62674 0.322005 4.86008C-0.0029951 5.10174 -0.094662 5.52674 0.105338 5.86841L1.98034 8.91841C2.16367 9.20175 2.48034 9.37675 2.83867 9.37675C3.18034 9.37675 3.50534 9.20175 3.68867 8.91841C3.98867 8.52674 9.71367 1.70174 9.71367 1.70174C10.4637 0.935077 9.55534 0.260078 8.95534 0.843411V0.851744Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9553 0.851744L8.98867 6.61008L7.40534 4.91841C7.11367 4.64341 6.65534 4.62674 6.322 4.86008C5.997 5.10174 5.90534 5.52674 6.10534 5.86841L7.98034 8.91841C8.16367 9.20175 8.48034 9.37675 8.83867 9.37675C9.18034 9.37675 9.50534 9.20175 9.68867 8.91841C9.98867 8.52674 15.7137 1.70174 15.7137 1.70174C16.4637 0.935077 15.5553 0.260078 14.9553 0.843411V0.851744Z"
        fill={color}
      />
    </svg>
  );
}
