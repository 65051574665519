import { Skeleton } from "@mui/material";
import { LoadingSkeleton } from "Components";
import { FC, Suspense } from "react";

export const withLoading =
  (WrappedComponent: FC<any>, type: number = 1) =>
  (props: any) => {
    const Loading = (() => {
      if (type === 1) {
        return () => <Skeleton variant="rounded" height={48} />;
      }
      if (type === 2) {
        return () => <LoadingSkeleton p={2} />;
      }
      return () => <div />;
    })();
    return (
      <Suspense fallback={<Loading />}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };
