import { styled } from "@mui/material";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Img = (props: LazyLoadImageProps) => (
  <StyledLazyLoadImage
    effect="blur"
    loading="lazy"
    alt={props.alt || "img"}
    {...props}
  />
);

const StyledLazyLoadImage = styled(LazyLoadImage)({
  display: "flex",
});
