import { Stack, styled } from "@mui/material";
import {
  CountryFlag,
  CrownTooltip,
  Img,
  LevelTooltip,
  Link,
  ReportButton,
  Row,
  SmText,
  TimeDiff,
  VerifySupplierTooltip,
  VerifyTooltip,
  XlTextSemiBold,
} from "Components";
import { DIRECT_PATHS } from "constants/routes";
import { memo, useMemo } from "react";
import { formatImgUrl } from "utils/helpers";
import { useIntl } from "react-intl";
import { SupplierType } from "types/supplier";
import { CIRCLE_VERIFIED_ICON } from "Assets/icons";
import {
  REPORT_TYPE,
  SUPPLIER_PLANS,
  VERIFICATION_LEVELS,
} from "constants/data";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import dayjs from "dayjs";

type Props = {
  supplierDetail: SupplierType;
};

const SupplierBaseInfors = ({ supplierDetail }: Props) => {
  let { company_country, country_code, plan, is_featured, last_active } =
    supplierDetail;
  const { verification_level, plan_name } = plan || {};
  const intl = useIntl();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  const NOW = dayjs();

  const currentPlan = useMemo(() => {
    return SUPPLIER_PLANS.find((plan) => plan.label === plan_name);
  }, [plan_name]);

  return (
    <StyledBaseSupplierInfors direction="row" spacing={2}>
      <Img
        src={formatImgUrl(supplierDetail?.farm_avatar, 2)}
        alt="img"
        className="company_img"
      />
      <Stack spacing={1} flex={1}>
        <Row justifyContent="space-between">
          <Stack spacing={1} direction="row" alignItems="center">
            <Link to={DIRECT_PATHS.companyDetail(supplierDetail?.name)}>
              <XlTextSemiBold className="company_name" component="h1">
                {supplierDetail?.farm_name}
              </XlTextSemiBold>
            </Link>
            {currentPlan?.icon && (
              <VerifyTooltip
                title={intl.formatMessage({ id: "this_is_a_premium_supplier" })}
              >
                <Img alt="icon" src={currentPlan?.icon} />
              </VerifyTooltip>
            )}
            <VerifySupplierTooltip
              title={<LevelTooltip level={verification_level} />}
              level={verification_level}
            />
          </Stack>

          {!isMobile && (
            <ReportButton
              type={REPORT_TYPE.supplier}
              title={supplierDetail.farm_name}
            />
          )}
        </Row>

        {last_active && (
          <SmText className="active-text">
            {intl.formatMessage(
              { id: "active_value_ago" },
              {
                value: (
                  <TimeDiff
                    endTime={NOW.format()}
                    startTime={dayjs(last_active).format()}
                  />
                ),
              }
            )}
          </SmText>
        )}

        <CountryFlag fd_country={company_country} {...{ country_code }} />
      </Stack>
    </StyledBaseSupplierInfors>
  );
};

const StyledBaseSupplierInfors = styled(Stack)({
  fontSize: 16,

  ".company_name": {},
  ".company_img": {
    width: 64,
    height: 64,
    objectFit: "contain",
  },
  ".address": {
    color: "var(--gray-color)",
  },
  ".buyers": {
    color: "var(--color)",
    display: "flex",
    alignItems: "center",
    gap: 4,
    backgroundColor: "#FFFAF5",
    padding: "7px 10px",
    borderRadius: 32,
  },
  ".response-time": {
    padding: "7px 10px",
    backgroundColor: "#E3FFEE",
    borderRadius: 16,
    span: {
      color: "var(--sub-color)",
    },
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});

export default memo(SupplierBaseInfors);
