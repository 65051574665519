import enTranslation from "./lang/en.json";
import viTranslation from "./lang/vi.json";

const APP_LOCALE = {
  EN: "en",
  VI: "vi",
};

const translationMessages = {
  en: enTranslation,
  vi: viTranslation,
};

export { APP_LOCALE, translationMessages };
