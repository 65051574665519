import { Box, Tab, styled } from "@mui/material";
import { Tabs } from "Components";
import { SyntheticEvent, lazy, memo, useMemo } from "react";
import { useIntl } from "react-intl";
import OverviewTab from "./OverviewTab";
import ProductsTab from "./ProductsTab";
import { withLoading } from "hocs/withLoading";
import { useSupplierContext } from "..";
import useNavigate from "hooks/useNavigate";
import { SEARCH_PARAMS } from "constants/routes";
import { objectToQueryString } from "helpers/search";
const Posts = withLoading(lazy(() => import("./Posts")));

const SupplierTabs = () => {
  const intl = useIntl();
  const { history } = useNavigate();
  const { setSelectedTab, selectedTab, postData } = useSupplierContext();
  const tabs = [
    { label: intl.formatMessage({ id: "products" }), value: "product" },
    { label: intl.formatMessage({ id: "overview" }), value: null },
    {
      label: intl.formatMessage({ id: "post" }),
      value: "post",
      hide: (postData?.data || []).length < 1,
    },
    // { label: intl.formatMessage({ id: "map" }), value: "map" },
  ];

  const onChangeTab = (event: SyntheticEvent, value: number) => {
    setSelectedTab(value);
    history.replace({
      search: objectToQueryString({
        [SEARCH_PARAMS.tab]: value,
      }),
    });
  };

  const ContentTab = useMemo(() => {
    switch (selectedTab) {
      case "product":
        return ProductsTab;

      case "post":
        return Posts;

      default:
        return OverviewTab;
    }
  }, [selectedTab]);

  return (
    <StyledSupplierTabs>
      <Tabs value={selectedTab} onChange={onChangeTab}>
        {tabs
          .filter((tab) => !tab.hide)
          .map(({ label, value }) => (
            <Tab key={value} {...{ label, value }} />
          ))}
      </Tabs>

      <ContentTab />
    </StyledSupplierTabs>
  );
};

const StyledSupplierTabs = styled(Box)({
  button: {
    "&.unselected": {
      backgroundColor: "transparent",
      color: "#000",
    },
  },
});

export default memo(SupplierTabs);
