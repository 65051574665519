import { lazy } from "react";

export { default as FarmItem } from "./FarmItem";
export { default as Farm } from "./Farm";
export { default as PageNotFound } from "./PageNotFound";
export { default as TopProducts } from "./TopProducts";
export const UserProfile = lazy(() => import("./user-profile"));
export const ResetPassword = lazy(() => import("./reset-password"));
export const QuickRFQ = lazy(() => import("./quick-rfq"));
export const Sitemap = lazy(() => import("./sitemap"));
export const Category = lazy(() => import("./category"));
export const Login = lazy(() => import("./Login"));
export const SendInquiry = lazy(() => import("./send-inquiry"));
export const Register = lazy(() =>
  import("./register" /* webpackChunkName: "register" */)
);
export const ActiveAccount = lazy(() => import("./active-account"));
export const Products = lazy(() => import("./products"));
export const Suppliers = lazy(() => import("./suppliers"));
export const Payment = lazy(() => import("./payment"));
export const ActiveAccountBuyer = lazy(() => import("./active-account-buyer"));
export const Unsubscribe = lazy(() => import("./unsubscribe"));