import { Grid, Typography } from "@mui/material";
import { LoadingSkeleton, Product, IntlMsg } from "Components";
import useIntersectionObserver from "hooks/useInfinityScroll";
import { useEffect, useState } from "react";
import { apiGetTopProducts } from "services/api/item";
import { ProductType } from "types";

type Props = {};

const TopProducts = (props: Props) => {
  const [isLoading, setisLoading] = useState(true);
  const [products, setProducts] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState({
    page: 0,
    limit: 20,
  });
  const [hasMore, setHasMore] = useState(false);
  const lastProductRef = useIntersectionObserver(
    () => {
      setPageLimit({ ...pageLimit, page: pageLimit.page + 1 });
    },
    isLoading,
    hasMore
  );

  const fetchData = async () => {
    let data = await apiGetTopProducts(pageLimit.limit, pageLimit.page);
    let newProducts = data.data?.message || [];
    let hasMore = newProducts?.length > 0;
    if (hasMore) {
      setProducts([...products, ...newProducts]);
    }
    setisLoading(false);
    setHasMore(hasMore);
  };

  useEffect(() => {
    setisLoading(true);
    fetchData();
  }, [pageLimit]);

  return (
    <>
      <Typography className="app_title" align="center" mt={4}>
        <IntlMsg id="FeaturedProducts"/>
      </Typography>

      <Grid container spacing={2}>
        {products.map((product: ProductType, i: number) => (
          <Grid item xs={6} sm={4} md={12 / 5} key={i}>
            <Product
              {...{ product }}
              swiper={false}
              ref={i === products.length - 1 ? lastProductRef : null}
            />
          </Grid>
        ))}
      </Grid>
      {isLoading && <LoadingSkeleton count={4} />}
    </>
  );
};

export default TopProducts;
