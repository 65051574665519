import { SVGProps } from "react";

export function RegenerateIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  stroke = "#344054",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_2764_13556)">
        <path
          d="M0.666748 2.66668V6.66668M0.666748 6.66668H4.66675M0.666748 6.66668L3.76008 3.76001C4.47658 3.04315 5.363 2.51948 6.33662 2.23786C7.31024 1.95624 8.33934 1.92584 9.32789 2.14952C10.3164 2.37319 11.2322 2.84363 11.9898 3.51696C12.7473 4.19028 13.322 5.04453 13.6601 6.00001M15.3334 13.3333V9.33334M15.3334 9.33334H11.3334M15.3334 9.33334L12.2401 12.24C11.5236 12.9569 10.6372 13.4805 9.66354 13.7622C8.68992 14.0438 7.66082 14.0742 6.67227 13.8505C5.68372 13.6268 4.76795 13.1564 4.01039 12.4831C3.25284 11.8097 2.67819 10.9555 2.34008 10"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2764_13556">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
