import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web";

export const apiGetTopCompanies = (limit = 5, page = 1) =>
  http.get(
    `${PRE_ENDPOINT}.v2.listing.get_top_farms?limit=${limit}&offset=${
      page * limit
    }`
  );

export const apiContactCompany = (data: any) =>
  http.post(
    `/api/method/freshdi.data.trace.farm.post_request_contact_farm`,
    data
  );

export const apiGetCompanyDetail = (data: any) =>
  http.post(`${PRE_ENDPOINT}.v3.farm.get_farm_common_info`, data);

export const apiGetCompanyOverview = (data: any) =>
  http.post(`${PRE_ENDPOINT}.v3.farm.get_farm_overview`, data);

export const apiGetSuppliersBySearch = (data?: any) =>
  http.post(`${PRE_ENDPOINT}.v3.listing.get_suppliers_by_search`, data);

export const apiGetSuppliersByCategory = (data: any) =>
  http.post(`${PRE_ENDPOINT}.v3.listing.get_suppliers_by_cate`, data);

export const apiGetBizCardFarm = (data: any) =>
  http.post(
    `${PRE_ENDPOINT}.v3.request.create_get_biz_card_request_farm`,
    data
  );

export const apiGetBizCardItem = (data: any) =>
  http.post(
    `${PRE_ENDPOINT}.v3.request.create_get_biz_card_request_item`,
    data
  );

export const apiGetBizCardRfq = (data: any) =>
  http.post(`${PRE_ENDPOINT}.v3.request.create_get_biz_card_request_rfq`, data);

export const apiGetFeaturedSuppliers = (data?: any) =>
  http.get(`${PRE_ENDPOINT}.v3.listing.get_featured_suppliers`, {
    params: data,
  });

export const apiGetSidebarItems = (params = {}) =>
  http.post(`${PRE_ENDPOINT}.v3.farm.get_right_side_items`, params);
