import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import useStyles from "./styles";
import messages from "./messages";
import { PATHS } from "../../../../constants/routes";
import useNavigate from "hooks/useNavigate";

const LeftBox = () => {
  const classes = useStyles();
  const { history } = useNavigate();
  const intl = useIntl();

  const handleClickBack = () => {
    history.goBack();
  };

  const handleClickHome = () => {
    window.location.href = PATHS.home;
  };

  return (
    <Box textAlign="center">
      <Typography className={classes?.notfoundTitle}>404.</Typography>

      <Typography className={classes?.notfoundSubTitle}>
        {intl.formatMessage(messages.notFound, { br: () => <br /> })}
      </Typography>

      <Box mt={4} className={classes.btnBox}>
        <Button className="left" onClick={handleClickBack}>
          {intl.formatMessage(messages.backPrePage)}
        </Button>
        <Box className="tmp" />
        <Button className="right" onClick={handleClickHome}>
          {intl.formatMessage(messages.home)}
        </Button>
      </Box>
    </Box>
  );
};

export default LeftBox;
