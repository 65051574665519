import { createContext, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { translationMessages } from "../../i18n";
import { getUser } from "redux/selectors/user";
import { langStorage } from "helpers/localStorage";
import { getBrowserLanguage } from "helpers/browser";

const LanguageContext = createContext({
  language: "vi",
  setLanguage: (value) => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children, ...restProps }) => {
  const [currentLanguage, setCurrentLanguage] = useState("vi");
  const user = useSelector(getUser);
  const { pathname } = window.location;

  useEffect(() => {
    const prepareLocalize = () => {
      // Xử lý đa ngôn ngữ theo đường link cho post
      if (pathname.includes("/en/")) {
        setCurrentLanguage("en");
        return;
      }
      if (pathname.includes("/vi/")) {
        setCurrentLanguage("vi");
        return;
      }

      setCurrentLanguage(
        (user?.is_logged_in && user?.language) ||
          langStorage.get("selected") ||
          getBrowserLanguage()
      );
    };
    prepareLocalize();
  }, [navigator.language, pathname, user?.language]);

  return (
    <IntlProvider
      {...restProps}
      locale={currentLanguage}
      messages={translationMessages[currentLanguage]}
      defaultLocale={"vi"}
    >
      <LanguageContext.Provider
        value={{ language: currentLanguage, setLanguage: setCurrentLanguage }}
      >
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

export default LanguageProvider;
