import { BoxProps } from "@mui/material";
import { ReactNode, lazy } from "react";

const Header = lazy(() => import("Components/Header"));
const Footer = lazy(() => import("Components/Footer"));
const Main = lazy(() => import("Components/Main"));

type Props = {
  children?: ReactNode;
  mainprops?: BoxProps;
  hideSearch?: boolean;
};

const Layout = (props: Props) => {
  const { children, hideSearch } = props;
  return (
    <>
      <Header {...{ hideSearch }} />
      <Main {...props.mainprops}>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
