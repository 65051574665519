import { SVGProps } from "react";

export function SendIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// export function EmailIcon({
//   width = 38,
//   height = 38,
//   viewBox = "0 0 38 38",
//   stroke = "white",
//   xmlns = "http://www.w3.org/2000/svg",
//   ...props
// }: SVGProps<SVGSVGElement>) {
//   return (
//     <svg
//       width={width}
//       height={height}
//       viewBox={viewBox}
//       fill="none"
//       xmlns={xmlns}
//       {...props}
//     >
//       <path
//         d="M34.375 9.375C34.375 7.65625 32.9688 6.25 31.25 6.25H6.25C4.53125 6.25 3.125 7.65625 3.125 9.375M34.375 9.375V28.125C34.375 29.8438 32.9688 31.25 31.25 31.25H6.25C4.53125 31.25 3.125 29.8438 3.125 28.125V9.375M34.375 9.375L18.75 20.3125L3.125 9.375"
//         stroke={stroke}
//         strokeWidth="3.3"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// }
