import {
  Checkbox,
  MenuItem,
  Stack,
  StackProps,
  Typography,
  styled,
} from "@mui/material";
import {
  AcceptButton,
  Input,
  Row,
  Select,
  SendInquiryButton,
  SendMessageButton,
} from "Components";
import { useIntl } from "react-intl";
import { BaseSupplierInfors } from "./Supplier";
import { Controller, useForm } from "react-hook-form";
import useAuth from "hooks/useAuth";
import useGetData from "hooks/useGetData";
import { apiGetAllCategories } from "services/api/category";
import { useEffect, useMemo } from "react";
import { CategoryType } from "types";
import { useLoading } from "providers/loading";
import { apiContactCompany } from "services/api/company";
import { useNotify } from "providers/notify";
import { useSelector } from "react-redux";
import { getCompany } from "redux/selectors/company";
import { useItemContext } from "..";

export const ContactSupplierCard = (
  props: StackProps & { onlybutton?: boolean }
) => {
  const { data } = useItemContext();
  const { identity } = data;

  const intl = useIntl();
  return (
    <StyledContactSupplierCard spacing={2} {...props}>
      {!props.onlybutton && (
        <>
          <Typography className="bold" component="h2">
            {intl.formatMessage({ id: "contact_supplier" })}
          </Typography>

          <BaseSupplierInfors />
        </>
      )}

      <SendInquiryButton
        className="send-inq-btn"
        data={{ farm_id: data?.farm }}
      />
      <SendMessageButton identity={identity} />
    </StyledContactSupplierCard>
  );
};

export const ContacctSupplierForm = (props: StackProps) => {
  const intl = useIntl();
  const company = useSelector(getCompany);
  const { isLoggedIn } = useAuth();
  const { setNotify } = useNotify();
  const { setLoading } = useLoading();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      farm_id: "",
      note: "",
      company_name: "",
      product_category_id: "",
      customer_name: "",
      is_share_business_card: true,
      order_method_data: {
        delivery_datetime: new Date(),
        customer_phone: "",
      },
    },
  });
  const { data } = useGetData({ promise: apiGetAllCategories });

  const onSubmit = (data: any) => {
    setLoading(true);
    apiContactCompany(data)
      .then((res) => {
        setLoading(false);
        setNotify({
          open: true,
          type: "success",
          msg: intl.formatMessage({ id: "Common.sent" }),
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setNotify({
          open: true,
          type: "error",
          msg: <Typography>{err?.response?.data?.message}</Typography>,
        });
      });
  };

  useEffect(() => {
    setValue("farm_id", company?.name);
  }, [company]);

  const categories = useMemo(() => data, [data]);

  return (
    <Stack
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      {/* <Input placeholder="Select RFQ from my account" /> */}
      {!isLoggedIn && (
        <>
          <Input
            placeholder={intl.formatMessage({ id: "company" })}
            {...register("company_name", {
              required: intl.formatMessage({ id: "Input.Error.requied" }),
            })}
            error={!!errors.company_name}
            helperText={errors.company_name?.message}
          />

          <Select
            placeholder={intl.formatMessage({ id: "interest_categories" })}
            {...register("product_category_id")}
          >
            {categories?.map((category: CategoryType) => {
              let { name, category_name } = category;
              return (
                <MenuItem key={name} value={name}>
                  {category_name}
                </MenuItem>
              );
            })}
          </Select>
        </>
      )}

      <Input
        placeholder={intl.formatMessage({ id: "enter_product_details" })}
        {...register("note", {
          required: intl.formatMessage({ id: "Input.Error.requied" }),
        })}
        multiline
        rows={4}
        error={!!errors.note}
        helperText={errors.note?.message}
      />
      <Row spacing={1}>
        <Controller
          name="is_share_business_card"
          control={control}
          render={({ field }) => (
            <Checkbox
              sx={{ p: 0 }}
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <Typography sx={{ color: "var(--gray-color)" }}>
          {intl.formatMessage({ id: "send_with_business_card" })}
        </Typography>
      </Row>

      <AcceptButton type="submit">
        {intl.formatMessage({ id: "contact_supplier" })}
      </AcceptButton>
    </Stack>
  );
};

const StyledContactSupplierCard = styled(Stack)({
  fontSize: 16,
  ".get_card_button": {
    "&.Mui-disabled": {
      opacity: 0.6,
    },
  },
  ".send-inq-btn": {
    minHeight: 48,
  },
});
