import { Pagination, PaginationProps, styled } from "@mui/material";

const Pagnigation = (props: PaginationProps) => {
  return <StyledPagination variant="outlined" {...props} />;
};

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul": {
    justifyContent: "center",
    "& .Mui-selected": {
      color: "var(--app-sub-bg-color)",
    },
    "& .MuiPaginationItem-root": {
      // backgroundColor: "#fff",
    },
  },
});

export default Pagnigation;
