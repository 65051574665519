import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web";

export const apiGetAllCategories = (params = {}) =>
  http.get(`${PRE_ENDPOINT}.v3.home.get_product_categories`, params);

export const apiGetAllCategoriesStat = () =>
  http.get(`${PRE_ENDPOINT}.v3.rfq.get_category_stat`);

export const apiGetTopCategories = () =>
  http.get(`${PRE_ENDPOINT}.v3.home.get_featured_product_categories`);
