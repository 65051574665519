import { Tabs as TabsLib, styled } from "@mui/material";

const Tabs = styled(TabsLib)({
  minHeight: 36,
  // boxShadow: "0px 1px 3px 0px #1018281A, 0px 1px 2px 0px #1018280D",
  ".MuiTabs-flexContainer": {
    borderRadius: 6,
    width: "fit-content",
    boxShadow: "0px 1px 3px 0px #1018281a, 0px 1px 2px 0px #1018280f",
    overflow: "hidden",
  },
  ".MuiButtonBase-root": {
    padding: "8px 12px",
    textTransform: "initial",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 500,
    color: "var(--gray)",
    minHeight: 36,
  },
  ".MuiButtonBase-root.Mui-selected, .MuiButtonBase-root.Mui-selected:hover": {
    color: "#fff",
    backgroundColor: "#027B30",
  },
  ".MuiButtonBase-root:hover": {
    color: "#027B30",
  },
  ".MuiTabs-scroller": {
    padding: "4px 0",
  },
  ".MuiTabs-indicator": {
    display: "none",
  },
  ".MuiButtonBase-root:not(last-child)": {},
});

export default Tabs;
