import { Box, Stack, StackProps, styled } from "@mui/material";
import { DynamicMedia, DynamicThumbnail } from "Components";
import { useEffect, useState } from "react";
import { checkIsVideo, formatImgUrl } from "utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";

type Props = {
  medias: any[];
};

const SupplierMedias = (props: Props & StackProps) => {
  const { medias = [], ...otherProps } = props;
  const [swiperRef, setSwiperRef] = useState<any>();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const onClickNext = () => {
    let newIndex = selectedImageIndex + 1;
    setSelectedImageIndex(newIndex >= medias.length ? 0 : newIndex);
    swiperRef?.slideNext();
  };

  const onClickBack = () => {
    let newIndex = selectedImageIndex - 1;
    setSelectedImageIndex(newIndex > 0 ? newIndex : medias.length - 1);
    swiperRef?.slidePrev();
  };

  useEffect(() => {
    if (medias?.length > 0) {
      setSelectedImageIndex(0);
    }
  }, []);

  const handleChangePreview = (index: number) => {
    setSelectedImageIndex(index);
  };

  if (medias?.length < 1) {
    return null;
  }

  return (
    <StyledItemBanners
      id="all-order-item-banner"
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      {...otherProps}
    >
      {!isMobile && (
        <Box className="previews_box" mt={2}>
          <Swiper
            slidesPerView={4}
            spaceBetween={12}
            direction="vertical"
            ref={swiperRef}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onInit={(ev) => {
              setSwiperRef(ev);
            }}
          >
            {medias?.map((img, index) => (
              <SwiperSlide key={index}>
                <DynamicThumbnail
                  classes="previewBanner"
                  att={{
                    opacity: index === selectedImageIndex ? 1 : "0.5",
                  }}
                  url={formatImgUrl(
                    img?.url,
                    undefined,
                    checkIsVideo(img?.url)
                  )}
                  isVideo={checkIsVideo(img?.url)}
                  onClick={() => handleChangePreview(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <IoIosArrowUp className="up" onClick={onClickBack} />
          <IoIosArrowDown className="down" onClick={onClickNext} />
        </Box>
      )}

      <Box className="mainBanner">
        <div
          className="wrapper-blur"
          style={{
            backgroundImage: `url(${formatImgUrl(
              medias[selectedImageIndex]?.url
            )})`,
          }}
        />

        <DynamicMedia
          url={formatImgUrl(medias[selectedImageIndex]?.url)}
          videoProps={{ width: "100%" }}
        />
      </Box>

      {isMobile && (
        <Box className="previews_box" mt={2}>
          <Swiper
            slidesPerView={4}
            spaceBetween={12}
            ref={swiperRef}
            onInit={(ev) => {
              setSwiperRef(ev);
            }}
          >
            {medias?.map((img, index) => (
              <SwiperSlide key={index}>
                <DynamicThumbnail
                  classes="previewBanner"
                  att={{
                    opacity: index === selectedImageIndex ? 1 : "0.5",
                  }}
                  url={formatImgUrl(
                    img?.url,
                    undefined,
                    checkIsVideo(img?.url)
                  )}
                  isVideo={checkIsVideo(img?.url)}
                  onClick={() => handleChangePreview(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <IoIosArrowBack className="up" onClick={onClickBack} />
          <IoIosArrowForward className="down" onClick={onClickNext} />
        </Box>
      )}
    </StyledItemBanners>
  );
};

const StyledItemBanners = styled(Stack)(({ theme }) => ({
  height: 360,
  ".mainBanner": {
    borderRadius: 8,
    flex: 1,
    overflow: "hidden",
    position: "relative",
    ".wrapper-blur": {
      top: 0,
      width: "100%",
      height: "100%",
      filter: "blur(16px)",
      position: "absolute",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
    "& img, video": {
      position: "relative",
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
    "& .qrImg": {
      height: "30%",
      width: "auto",
      position: "absolute",
      right: 8,
      bottom: 12,
    },
  },
  ".previews_box": {
    width: 70,
    padding: "32px 0",
    position: "relative",
    ".swiper": {
      height: "100%",
    },
    ".up, .down": {
      position: "absolute",
      transform: "translateX(-50%)",
      cursor: "pointer",
      left: "50%",
    },
    ".up": {
      top: "0",
    },
    ".down": {
      bottom: 0,
    },
  },
  ".previewBanner": {
    borderRadius: 8,
    height: "70px !important",
    width: "70px !important",
    minWidth: 70,
    cursor: "pointer",
    // "& img, video": {
    // },
  },
  ".swiper-slide": {
    width: "auto",
  },
  [theme.breakpoints.down("md")]: {
    ".previews_box": {
      width: "100%",
      padding: "0 32px",
      ".swiper": {
        height: "auto",
      },
      ".up, .down": {
        transform: "translateY(-50%)",
        top: "50%",
        left: "auto",
      },
      ".up": {
        left: "0",
      },
      ".down": {
        right: 0,
      },
    },
  },
}));

export default SupplierMedias;
