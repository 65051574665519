export const FREE_BENEFIT_IMG = require("./benefit/free.svg").default;
export const FIRST_LEADING_BENEFIT_IMG =
  require("./benefit/first_leading.webp").default;
export const APPLY_TECH_BENEFIT_IMG =
  require("./benefit/apply_tech.webp").default;
export const SOURCING_SERVICE_IMG =
  require("./service/sourcing_service.png").default;
export const DEFAULT_WALLPAPER_IMG =
  require("./default_wallpaper.webp").default;
export const EN_HOMEPAGE_1 = require("./Eng_Homepage_1.webp").default;
export const VI_HOMEPAGE_1 = require("./Viet_Homepage_1.webp").default;
export const EN_HOMEPAGE_2 = require("./Eng_Homepage_2.webp").default;
export const VI_HOMEPAGE_2 = require("./Viet_Homepage_2.webp").default;
export const EN_HOMEPAGE_3 = require("./Eng_Homepage_3.webp").default;
export const VI_HOMEPAGE_3 = require("./Viet_Homepage_3.webp").default;
export const AI_PROCESSING_IMG = require("./ai_processing.gif").default;
export const EN_COMPARE_PRICING =
  require("./Eng_ComparePricing_Homepage.webp").default;
export const VI_COMPARE_PRICING =
  require("./Viet_ComparePricing_Homepage.webp").default;
export const REGISTER_BANNER_EN_DESKTOP_IMG =
  require("./banner/register_banner_en_desktop.webp").default;
export const REGISTER_BANNER_EN_MOBILE_IMG =
  require("./banner/register_banner_en_mobile.webp").default;
export const REGISTER_BANNER_VN_DESKTOP_IMG =
  require("./banner/register_banner_vn_desktop.webp").default;
export const REGISTER_BANNER_VN_MOBILE_IMG =
  require("./banner/register_banner_vn_mobile.webp").default;
export const BUY_PLAN_BANNER_EN_DESKTOP_IMG =
  require("./banner/buy_plan_banner_en_desktop.webp").default;
export const BUY_PLAN_BANNER_EN_MOBILE_IMG =
  require("./banner/buy_plan_banner_en_mobile.webp").default;
export const BUY_PLAN_BANNER_VN_DESKTOP_IMG =
  require("./banner/buy_plan_banner_vn_desktop.webp").default;
export const BUY_PLAN_BANNER_VN_MOBILE_IMG =
  require("./banner/buy_plan_banner_vn_desktop.webp").default;
export const BANNER_1 = require("./Banner_1.webp").default;
export const VI_BANNER_1 = require("./Viet_Banner_1.webp").default;
export const BANNER_2 = require("./Banner_2.webp").default;
export const VI_BANNER_2 = require("./Viet_Banner_2.webp").default;
export const BANNER_3 = require("./Banner_3.webp").default;
export const VI_BANNER_3 = require("./Viet_Banner_3.webp").default;
export const SUPPLIER_BG_EN_DESKTOP =
  require("./background/supplier_bg_en_desktop.webp").default;
export const SUPPLIER_BG_VN_DESKTOP =
  require("./background/supplier_bg_vn_desktop.webp").default;
export const SUPPLIER_BG_EN_MOBILE =
  require("./background/supplier_bg_en_mobile.webp").default;
export const SUPPLIER_BG_VN_MOBILE =
  require("./background/supplier_bg_vn_mobile.webp").default;
export const EN_GLOBAL_BG = require("./background/[Eng]_Login.webp").default;
export const VI_GLOBAL_BG = require("./background/[Viet]_Login.webp").default;
export const HOME_BANNER_EN_DESKTOP =
  require("./banner/home_banner_en_desktop.webp").default;
export const HOME_BANNER_VN_DESKTOP =
  require("./banner/home_banner_vn_desktop.webp").default;
export const HOME_BANNER_EN_MOBILE =
  require("./banner/home_banner_en_mobile.webp").default;
export const HOME_BANNER_VN_MOBILE =
  require("./banner/home_banner_vn_mobile.webp").default;
export const HOME_BANNER_EN_DESKTOP_2 =
  require("./banner/home_banner_en_desktop_2.webp").default;
export const HOME_BANNER_VN_DESKTOP_2 =
  require("./banner/home_banner_vn_desktop_2.webp").default;
export const HOME_BANNER_EN_MOBILE_2 =
  require("./banner/home_banner_en_mobile_2.webp").default;
export const HOME_BANNER_VN_MOBILE_2 =
  require("./banner/home_banner_vn_mobile_2.webp").default;
export const HOME_BANNER_EN_DESKTOP_3 =
  require("./banner/home_banner_en_desktop_3.webp").default;
export const HOME_BANNER_VN_DESKTOP_3 =
  require("./banner/home_banner_vn_desktop_3.webp").default;
export const HOME_BANNER_EN_MOBILE_3 =
  require("./banner/home_banner_en_mobile_3.webp").default;
export const HOME_BANNER_VN_MOBILE_3 =
  require("./banner/home_banner_vn_mobile_3.webp").default;
export const TESTIMONIALS_LOGO_1 =
  require("./testimonials/Les Artisans du Monde.png").default;

export const TESTIMONIALS_LOGO_2 =
  require("./testimonials/Bruckmann GmbH.jpeg").default;

export const TESTIMONIALS_LOGO_3 =
  require("./testimonials/Interfresh Food and Beverage Company Limited.png").default;

export const TESTIMONIALS_LOGO_4 =
  require("./testimonials/Garden-to-global-exports-pvt-ltd.jpeg").default;

export const TESTIMONIALS_LOGO_5 =
  require("./testimonials/VERTEKS-MVE-NIG-LTD.jpg").default;

export const TESTIMONIALS_LOGO_6 =
  require("./testimonials/Guangdong Meteor Century International Trade Co.png").default;

export const TESTIMONIALS_LOGO_7 =
  require("./testimonials/PT-Najah-Triumph-Optima.png").default;

export const TESTIMONIALS_LOGO_8 =
  require("./testimonials/Akpobi-and-sons-enterprise.jpg").default;

export const TESTIMONIALS_LOGO_9 =
  require("./testimonials/Favdip-Global-Resources-Ltd.jpg").default;
