import { Box, BoxProps, Grid, InputAdornment } from "@mui/material";
import { Input, LoadingSkeleton, Pagnigation, Product, Text } from "Components";
import useGetData from "hooks/useGetData";
import { ChangeEvent, memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { apiGetProductsBySearch } from "services/api/item";
import { ProductType } from "types";
import { IoSearchOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

const ProductsTab = (props: BoxProps) => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [filters, setFilters] = useState({
    keyword: "",
    limit: 12,
    offset: 0,
  });
  const intl = useIntl();
  const { isLoading, data } = useGetData({
    promise: () => apiGetProductsBySearch({ farm_id: supplierId, ...filters }),
    deps: [filters, supplierId],
  });
  const products: ProductType[] = useMemo(
    () => data?.data?.items || [],
    [data?.data]
  );
  const total = useMemo(() => data?.data?.total || 0, [data]);

  const onUpdateFilters = (updateData: any) =>
    setFilters({ ...filters, ...updateData });

  const onChangeKeySearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onUpdateFilters({ keyword: e.target.value, offset: 0 });

  const currentPage = useMemo(
    () => Math.ceil(filters.offset / filters.limit + 1),
    [filters]
  );

  const pageTotal = useMemo(
    () => Math.ceil((total || 0) / filters.limit),
    [filters, total]
  );

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onUpdateFilters({
      offset: filters?.limit * (value - 1),
    });
  };

  return (
    <Box my={2} {...props}>
      <Input
        placeholder={intl.formatMessage({ id: "search_product" })}
        value={filters.keyword}
        onChange={onChangeKeySearch}
        sx={{ mb: 2 }}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IoSearchOutline />
            </InputAdornment>
          ),
        }}
      />
      {isLoading && <LoadingSkeleton />}
      {!isLoading && products.length < 1 && (
        <Text>{intl.formatMessage({ id: "no_record" })}</Text>
      )}
      {!isLoading && (
        <Grid container spacing={2}>
          {products.map((product, id) => (
            <Grid item xs={6} md={3}>
              <Product product={product} key={id} />
            </Grid>
          ))}
        </Grid>
      )}

      {pageTotal > 1 && (
        <Pagnigation
          count={pageTotal}
          sx={{ mt: 2 }}
          page={currentPage}
          onChange={handleChangePage}
        />
      )}
    </Box>
  );
};

export default memo(ProductsTab);
