import axios from "axios";
import { API_DOMAIN, TOKEN } from "constants/schemas";

const http = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: TOKEN? `token ${TOKEN}`: '',
  },
  withCredentials: true,
});

export default http;
