import {
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  debounce,
  styled,
} from "@mui/material";
import { LuSearch } from "react-icons/lu";
import { useIntl } from "react-intl";
import Menu from "./Menu";
import { useEffect, useRef, useState } from "react";
import { DIRECT_PATHS, PATHS, SEARCH_PARAMS } from "constants/routes";
import useNavigate from "hooks/useNavigate";
import { apiSearchProductCategory } from "services/api/helpers";
import { StyledAutocomplete } from "./Input";
import Row from "./Row";

const PRODUCT_OPTION = "products";
const SUPPLIER_OPTION = "suppliers";
const RFQ_OPTION = "rfqs";
const BUYER_OPTION = "buyers";

const options = [
  { label: PRODUCT_OPTION, placeholder: "search_products" },
  { label: SUPPLIER_OPTION, placeholder: "search_suppliers" },
  { label: RFQ_OPTION, placeholder: "search_rfqs" },
  { label: BUYER_OPTION, placeholder: "search_buyers" },
];

const HeaderSearch = (props: any) => {
  const intl = useIntl();
  const { useSearchParams, pathname } = useNavigate();
  const [selectedOption, setSelectedOption] = useState(
    useSearchParams.get(SEARCH_PARAMS.searchType) || PRODUCT_OPTION
  );
  const [searchKeyword, setSearchKeyword] = useState(
    useSearchParams.get(SEARCH_PARAMS.keyword) || ""
  );
  const [searchCategories, setsearchCategories] = useState<any[]>([]);
  const [searchCategoryLoading, setsearchCategoryLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const delayedFetchOptions = debounce((searchText: string) => {
    getSearchCategories(searchText);
    setSearchKeyword(searchText);
  }, 500);

  const getSearchCategories = (search: string | null) => {
    setsearchCategoryLoading(true);
    apiSearchProductCategory(search)
      .then((res) => {
        setsearchCategories(
          (res.data?.message?.data || []).map((c: any) => ({
            value: c.name,
            label: c.category_name,
          }))
        );
      })
      .finally(() => {
        setsearchCategoryLoading(false);
      });
  };

  const onClickSearch = (e: any) => {
    e?.preventDefault();
    const searchKeyword = inputRef.current ? inputRef.current?.value : "";

    if (searchKeyword.length > 0) {
      let selectedPathname = "";
      let selectedSearch = `${SEARCH_PARAMS.keyword}=${searchKeyword}&${SEARCH_PARAMS.searchType}=${selectedOption}`;

      switch (selectedOption) {
        case PRODUCT_OPTION:
          selectedPathname = PATHS.search;
          break;

        case SUPPLIER_OPTION:
          selectedPathname = PATHS.searchSuppliers;
          break;

        case RFQ_OPTION:
          selectedPathname = PATHS.rfqMarket;
          break;

        case BUYER_OPTION:
          selectedPathname = PATHS.buyers;
          break;

        default:
          break;
      }
      window.location.href = selectedPathname + "?" + selectedSearch;
    }
  };

  const onClickCategory = (value: string) => {
    let selectedPathname = "";

    switch (selectedOption) {
      case PRODUCT_OPTION:
        selectedPathname = PATHS.category + "/" + value;
        break;

      case SUPPLIER_OPTION:
        selectedPathname = PATHS.categorySupplier + "/" + value;
        break;

      case RFQ_OPTION:
        selectedPathname = DIRECT_PATHS.categoryRfqs(value);
        break;

      case BUYER_OPTION:
        selectedPathname = DIRECT_PATHS.categoryBuyer(value);
        break;

      default:
        break;
    }
    window.location.href = selectedPathname;
  };

  const onAutoFillOption = () => {
    const pathsMatchSupplier = ["/suppliers"];
    const pathsMatchProducts = ["/products"];
    const pathsMatchBuyer = ["/buyers"];
    const pathsMatchSourcing = ["/sourcing-requests"];
    if (pathsMatchSupplier.some((path) => pathname.includes(path))) {
      setSelectedOption(SUPPLIER_OPTION);
    } else if (pathsMatchProducts.some((path) => pathname.includes(path))) {
      setSelectedOption(PRODUCT_OPTION);
    } else if (pathsMatchBuyer.some((path) => pathname.includes(path))) {
      setSelectedOption(BUYER_OPTION);
    } else if (pathsMatchSourcing.some((path) => pathname.includes(path))) {
      setSelectedOption(RFQ_OPTION);
    }
  };

  useEffect(() => {
    getSearchCategories(null);
  }, []);

  useEffect(() => {
    onAutoFillOption();
  }, [pathname]);

  return (
    <StyledForm onSubmit={onClickSearch}>
      <StyledAutocomplete
        options={searchCategories}
        getOptionLabel={(option: any) => option.label || ""}
        loading={searchCategoryLoading}
        onChange={(e, newValue: any) => {
          onClickCategory(newValue?.value);
        }}
        renderInput={(params) => (
          <TextField
            {...{ ...params, ...props }}
            placeholder={intl.formatMessage({
              id: "what_are_you_looking_for",
            })}
            fullWidth
            value={searchKeyword}
            onChange={(e) => {
              delayedFetchOptions(e.target.value);
            }}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Row spacing={1}>
                    <Menu
                      buttonText={intl.formatMessage({ id: selectedOption })}
                      buttonProps={{ className: "choose-option" }}
                      value={selectedOption}
                    >
                      {options.map(({ label }) => (
                        <MenuItem
                          key={label}
                          selected={label === selectedOption}
                          onClick={() => setSelectedOption(label)}
                        >
                          {intl.formatMessage({ id: label })}
                        </MenuItem>
                      ))}
                    </Menu>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <LuSearch onClick={onClickSearch} />
                  </Row>
                </InputAdornment>
              ),
              endAdornment: null,
            }}
          />
        )}
      />
    </StyledForm>
  );
};

export const StyledForm = styled("form")(({ theme }) => ({
  flex: 1,
  maxWidth: 520,
  "& .MuiInputBase-root": {
    padding: "10px 14px",
    borderRadius: 6,
  },
  "& input": {
    paddingLeft: 0,
    color: "#667085",
  },
  "& .MuiInputAdornment-root": {
    cursor: "pointer",
  },
  ".choose-option": {
    fontSize: 14,
    padding: "8px 0",
  },
  ".MuiInputBase-root": {
    paddingRight: "8px !important",
  },
  "& .MuiSelect-icon": {
    display: "none",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    "& input": {
      fontSize: 12,
    },
  },
}));

export default HeaderSearch;
