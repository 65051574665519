import {
  ButtonProps,
  Dialog,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import { Img } from "./Img";
import {
  CircleChatIcon,
  EmailIcon,
  FACEBOOK_ICON,
  FlagIcon,
  GOOGLE_ICON,
  SellBagIcon,
} from "Assets/icons";
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { REACT_APP_FB_APP_ID, REACT_APP_GG_APP_ID } from "constants/schemas";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { apiRegisterWithGg } from "services/api/user";
import { useLoading } from "providers/loading";
import { MouseEvent, useCallback, useState } from "react";
import { useLanguageContext } from "./LanguageProvider";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";
import { encodeBase64 } from "helpers/base64";
import { useIntl } from "react-intl";
import { OpenTabLink, StyledA, StyledATag, TextSemibold } from "./Text";
import { getAllDataInQueryString, objectToQueryString } from "helpers/search";
import useChat from "hooks/useChat";
import { ReportForm } from "Components";
import useUser from "hooks/useUser";
import {
  StyledAddButton,
  StyledBaseButton,
  StyledButton,
  StyledCancelButton,
  StyledConfirmButton,
  StyledLgAcceptButton,
  StyledMailButton,
  StyledReportButton,
  StyledTextButton,
} from "./Button.styles";

const GoogleButton = (
  props: ButtonProps & {
    hideText?: boolean;
    onCallback?: any;
    text?: string;
    onCustomConfirm?: (data: any) => void;
  }
) => {
  const { text = "login_gg", onCustomConfirm } = props;
  const { directKeepSearch, useSearchParams } = useNavigate();
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;
  const { setLoading } = useLoading();
  const { language } = useLanguageContext();
  const intl = useIntl();
  const { onGetUserInformation, onDirectNextPageOfLogin } = useAuth();

  const onConfirm = useCallback((data: any) => {
    if (onCustomConfirm) {
      onCustomConfirm(data);
    } else {
      const { code } = data || {};
      setLoading(true);
      const queryData = getAllDataInQueryString(useSearchParams);

      apiRegisterWithGg({
        code,
        language,
        redirect_uri,
        ...queryData,
        check_signup: true,
      })
        .then((res) => {
          setLoading(false);
          if (props.onCallback) {
            props.onCallback(res);
          } else {
            if (res?.data?.is_signed_up) {
              onGetUserInformation()
                .then((err) => {
                  onDirectNextPageOfLogin();
                })
                .catch((err) => {
                  console.log(false);
                });
            } else {
              const searchParams = new URLSearchParams({
                [SEARCH_PARAMS.uid]: code,
                [SEARCH_PARAMS.mail]: res.data.user,
                [SEARCH_PARAMS.username]: res.data.full_name,
              }).toString();
              directKeepSearch(PATHS.register, searchParams);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <LoginSocialGoogle
      client_id={REACT_APP_GG_APP_ID || ""}
      redirect_uri={redirect_uri}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      onResolve={({ data }: IResolveParams) => {
        onConfirm(data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <StyledButton fullWidth {...props}>
        <Img src={GOOGLE_ICON} alt="icon" />
        {!props.hideText && (
          <TextSemibold>{intl.formatMessage({ id: text })}</TextSemibold>
        )}
      </StyledButton>
    </LoginSocialGoogle>
  );
};

const FacebookButton = (props: ButtonProps) => {
  const onConfirm = useCallback((data: any) => {
    console.log("first");
  }, []);
  return (
    <LoginSocialFacebook
      appId={REACT_APP_FB_APP_ID || ""}
      fieldsProfile={
        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
      }
      redirect_uri={PATHS.home}
      onResolve={({ data }: IResolveParams) => onConfirm(data)}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <StyledButton
        startIcon={<Img src={FACEBOOK_ICON} alt="icon" />}
        {...props}
      >
        Facebook
      </StyledButton>
    </LoginSocialFacebook>
  );
};

const ConfirmButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

type InquiryData = {
  farm_id?: string;
  farm_item_id?: string;
  unit?: string;
  quantity?: number | string;
};

const SendInquiryButton = (props: ButtonProps & { data?: InquiryData }) => {
  const { data } = props;
  const titleButtonId = "contact_supplier_2";
  const intl = useIntl();
  const { history } = useNavigate();
  const onClickSendInquiry = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let { farm_id, farm_item_id, quantity = "", unit = "" } = data || {};

    history.push({
      pathname: PATHS.sendInquiry,
      search: farm_id
        ? `${SEARCH_PARAMS.farmId}=${encodeBase64(farm_id)}${
            farm_item_id
              ? "&" +
                objectToQueryString({
                  [SEARCH_PARAMS.itemId]: encodeBase64(farm_item_id),
                  [SEARCH_PARAMS.quantity]: encodeBase64(quantity),
                  [SEARCH_PARAMS.unit]: encodeBase64(unit),
                })
              : ""
          }`
        : "",
    });
  };
  return (
    <ConfirmButton onClick={onClickSendInquiry} {...props}>
      {intl.formatMessage({ id: titleButtonId })}
    </ConfirmButton>
  );
};

export const SendMessageButton = ({ identity }: { identity: string }) => {
  const { onAddChatToShowList } = useChat();
  const t = useIntl();

  return (
    <AcceptButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onAddChatToShowList(identity);
      }}
      endIcon={<CircleChatIcon color="#fff" height={30} />}
    >
      {t.formatMessage({ id: "send_message" })}
    </AcceptButton>
  );
};

export const ReportButton = ({
  mobile,
  type,
  title,
}: {
  mobile?: boolean;
  type: string;
  title?: string;
}) => {
  const t = useIntl();
  const [showReport, setShowReport] = useState(false);

  const onCloseReport = () => setShowReport(false);

  return (
    <>
      {!mobile && (
        <IconButton className="fit" onClick={() => setShowReport(true)}>
          <FlagIcon />
        </IconButton>
      )}
      {mobile && (
        <StyledReportButton
          endIcon={<FlagIcon />}
          onClick={() => setShowReport(true)}
        >
          {t.formatMessage({ id: "report" })}
        </StyledReportButton>
      )}

      <Dialog open={showReport} onClose={onCloseReport}>
        <ReportForm onClose={onCloseReport} {...{ mobile, type, title }} />
      </Dialog>
    </>
  );
};

const AddButton = (props: ButtonProps) => {
  return <StyledAddButton {...props} />;
};

const CancelButton = (props: ButtonProps) => {
  return <StyledCancelButton {...props} />;
};

const AcceptButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

export const LgAcceptButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

const BaseButton = (props: ButtonProps) => {
  return <StyledBaseButton {...props} />;
};

const TextButton = (props: ButtonProps) => {
  return <StyledTextButton variant="text" {...props} />;
};

const YellowButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

const SellingButton = (props: ButtonProps) => {
  const t = useIntl();
  const { isLoggedIn } = useUser();
  return (
    <StyledA href={PATHS.bizDomain}>
      <StyledTextButton
        variant="text"
        style={{ color: "var(--green-600)" }}
        startIcon={<SellBagIcon />}
        {...props}
      >
        {t.formatMessage({ id: isLoggedIn ? "seller_center" : "StartSelling" })}
      </StyledTextButton>
    </StyledA>
  );
};

export const MailButton = (props: IconButtonProps) => {
  return (
    <OpenTabLink href={PATHS.contactUs}>
      <StyledMailButton children={<EmailIcon color="#fff" />} {...props} />
    </OpenTabLink>
  );
};

export {
  GoogleButton,
  FacebookButton,
  ConfirmButton,
  AddButton,
  CancelButton,
  AcceptButton,
  BaseButton,
  TextButton,
  SendInquiryButton,
  YellowButton,
  SellingButton,
};
