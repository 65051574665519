import { useState, useMemo, createContext, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { Breadcrumbs, LoadingSkeleton } from "Components";
import useGetData from "hooks/useGetData";
import { apiGetCompanyDetail } from "services/api/company";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import {
  ContactSupplier,
  SupplierWallpaper,
  SupplierBaseInfors,
  SupplierTabs,
  SuggestProducts,
} from "./components";
import { Grid, Stack } from "@mui/material";
import { apiGetFarmPosts } from "services/api/smartTrace";
import useNavigate from "hooks/useNavigate";

const SupplierContext = createContext();

export const useSupplierContext = () => useContext(SupplierContext);

const LIMIT_POST = 10;

const Farm = () => {
  const { supplierId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { useSearchParams } = useNavigate();
  const tabParam = useSearchParams.get(SEARCH_PARAMS.tab);

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tabParam);
  const [filterPost, setFilterPost] = useState({
    offset: 0,
    limit: LIMIT_POST,
  });

  const { data: supplierDetailData } = useGetData({
    promise: () => {
      setLoading(true);
      return apiGetCompanyDetail({ farm_id: supplierId }).catch((err) => {
        setLoading(false);
        history.replace(PATHS.notFound);
      });
    },
    deps: [supplierId],
  });

  const { isLoading: isLoadingPost, data: postData } = useGetData({
    promise: () => apiGetFarmPosts({ ...filterPost, farm_id: supplierId }),
    deps: [supplierId, filterPost],
  });

  const supplierDetail = useMemo(() => {
    setLoading(false);
    return supplierDetailData?.data?.farm || {};
  }, [supplierDetailData?.data]);

  const contextData = {
    postData: postData?.data,
    supplierDetail,
    setSelectedTab,
    selectedTab,
    isLoadingPost,
    setFilterPost,
    filterPost,
  };

  return (
    <SupplierContext.Provider value={contextData}>
      {!loading && supplierDetail.name && (
        <>
          <Breadcrumbs
            data={[
              { label: intl.formatMessage({ id: "home" }), href: PATHS.home },
              { label: supplierDetail.farm_name },
            ]}
          />

          <SupplierWallpaper wallpaper={supplierDetail.wallpaper} />

          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Stack className="card" spacing={2}>
                  <SupplierBaseInfors {...{ supplierDetail }} />
                  <SupplierTabs />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                  <ContactSupplier className="card" />
                  <SuggestProducts className="card" />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {loading && <LoadingSkeleton count={3} />}
    </SupplierContext.Provider>
  );
};

export default Farm;
