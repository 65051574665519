export const BREAKPOINT_VALUES = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1440,
  xl: 1530,
};

export const AUTO_PLAY_ITEM_TIME = 5000;
export const AUTO_PLAY_BANNER_TIME = 3000;

export const MENU_WIDTH = {
  expanded: 270,
  collaped: 80,
  collapedMobile: 52,
};

export const MAX_NOTI_CHAR_QLT = 99;

export const MAX_CHAT_CHARACTER_LEN = 500;

export const PAYMENT_PAGES = {
  preview: "preview",
  detail: "detail",
  statusSuccessful: "status-successful",
  statusFail: "status-fail",
};
