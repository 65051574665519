import { Box, Dialog, Divider, IconButton, Stack } from "@mui/material";

import {
  StyledCenterStack,
  StyledHeader,
  StyledMobileHeader,
  StyledRegisterButton,
} from "./Header.styles";
import LogoFreshdi from "./LogoFreshdi";
import { PATHS } from "constants/routes";
import { memo, useState } from "react";
import AuthBar from "./AuthBar";

import IntlMsg from "./IntlMsg";
import Row from "./Row";
import { Container } from "./Container";
import {
  HeaderChat,
  HeaderServices,
  Img,
  Link,
  Notification,
  SelectLanguage,
  SellingButton,
  SmTextMd,
  TextButton,
} from "Components";
import useAuth from "hooks/useAuth";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { IoMdMenu } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { POST_RFQ } from "../Assets/icons";
import HeaderSearch from "./HeaderSearch";
import HeaderLinks from "./HeaderLinks";
import { useIntl } from "react-intl";

type Props = {
  hideSearch?: boolean;
};

const Header = ({ hideSearch }: Props) => {
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  const { isLoggedIn } = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  const onClickMenu = () => {
    setShowDialog(true);
  };

  const onCloseDrawer = () => {
    setShowDialog(false);
  };

  return (
    <StyledHeader>
      <Container>
        <StyledCenterStack justifyContent="space-between" px={{ xs: 0, md: 2 }}>
          {isMobile && (
            <Row spacing={1}>
              <IconButton onClick={onClickMenu}>
                <IoMdMenu />
              </IconButton>
              <SelectLanguage
                buttonProps={{ sx: { justifyContent: "flex-start" } }}
                showLabel={false}
              />
            </Row>
          )}
          {isMobile && <LogoFreshdi />}
          {!isMobile && (
            <Row spacing={4} flex={1}>
              <LogoFreshdi />
              {!hideSearch && <HeaderSearch />}
            </Row>
          )}
          {!isMobile && (
            <StyledCenterStack justifyContent="flex-end">
              <QuickPostRFQ />
              <SellingButton className="app-link-btn" />
              <SelectLanguage buttonProps={{ className: "app-link-btn" }} />
              <Row ml={1}>
                {isLoggedIn && (
                  <>
                    <Notification />
                    <HeaderChat />
                  </>
                )}
                <AuthBar />
                {!isLoggedIn && <RegisterButton />}
              </Row>
            </StyledCenterStack>
          )}
          {isMobile && (
            <Row spacing={1.5} minWidth={96} justifyContent="flex-end">
              {isLoggedIn && (
                <>
                  <Notification />
                  <HeaderChat />
                </>
              )}
              <AuthBar hideUserName />
            </Row>
          )}
        </StyledCenterStack>
        {!hideSearch && isMobile && <HeaderSearch fullWidth sx={{ mt: 1 }} />}
      </Container>

      {!isMobile && <Divider sx={{ mt: 2 }} />}

      {!isMobile && (
        <Container>
          <Row justifyContent="space-between">
            <HeaderLinks />
            <HeaderServices />
          </Row>
        </Container>
      )}

      <Dialog fullScreen open={showDialog} onClose={onCloseDrawer}>
        <MobileHeader onClose={onCloseDrawer} />
      </Dialog>
    </StyledHeader>
  );
};

const RegisterButton = () => {
  const { onDirectRegister } = useAuth();
  const onClickRegister = () => {
    onDirectRegister();
  };

  return (
    <StyledRegisterButton onClick={onClickRegister}>
      <IntlMsg id="JoinForFree" />
    </StyledRegisterButton>
  );
};

const MobileHeader = ({ onClose }: { onClose?: any }) => {
  const { isLoggedIn } = useAuth();
  const t = useIntl();

  return (
    <StyledMobileHeader>
      <Row justifyContent="space-between" p={2} pb={1}>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
        <LogoFreshdi />
        <AuthBar hideUserName={true} />
      </Row>

      <Stack spacing={2} className="mobileheader">
        <Divider />
        <Stack spacing={2} p={2}>
          <HeaderLinks inrow />
          <Link to={PATHS.quickRfq} onClick={onClose}>
            <SmTextMd className="quickrfq_button">
              <Img src={POST_RFQ} alt="post rfq" />
              {t.formatMessage({ id: "quick_post_rfq" })}
            </SmTextMd>
          </Link>
          <SellingButton className="selling-btn" />
        </Stack>
        <Divider />

        <Stack spacing={2} p={2}>
          <Box mx={isLoggedIn ? "0" : "auto"}>
            <AuthBar isMobileView />
          </Box>
          {!isLoggedIn && <RegisterButton />}
        </Stack>
      </Stack>
    </StyledMobileHeader>
  );
};

const QuickPostRFQ = ({ hideMessage = false }) => {
  return (
    <Link to={PATHS.quickRfq}>
      <TextButton
        sx={{
          color: "#F79009",
          ".MuiButton-startIcon": {
            mr: hideMessage ? 0 : 1,
          },
          minWidth: 16,
          fontWeight: 600,
        }}
        className="app-link-btn"
        startIcon={<Img src={POST_RFQ} alt="post rfq" />}
      >
        {!hideMessage && <IntlMsg id="QuickPostRFQ" />}
      </TextButton>
    </Link>
  );
};

export default memo(Header);
