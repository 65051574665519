import {
  Breadcrumbs as BreadcrumbsLib,
  BreadcrumbsProps,
  Link,
  styled,
} from "@mui/material";
import { memo } from "react";

export type BreadCrumbsData = {
  label: string;
  href?: string;
};

const Breadcrumbs = ({
  data = [],
  ...props
}: BreadcrumbsProps & { data: BreadCrumbsData[] }) => {
  return (
    <StyledBreadcums {...props}>
      {data.map(({ label, href }: BreadCrumbsData, index: number) => (
        <Link key={index} underline="hover" color="inherit" href={href}>
          {label}
        </Link>
      ))}
    </StyledBreadcums>
  );
};

const StyledBreadcums = styled(BreadcrumbsLib)({
  // padding: "0 8px",
  maxWidth: "calc(100% / 3 * 2)",
  a: {
    color: "#00000099",
    fontSize: 14,
  },
  ol: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    flexWrap: "nowrap",
    "li:last-child": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

export default memo(Breadcrumbs);
