import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";

import {
  LogoutButton,
  StyledAuthButton,
  StyledAvatar,
  StyledDialog,
  StyledMenu,
  confirmBtnStyles,
} from "./styles";
import {
  ConfirmBtns,
  Img,
  IntlMsg,
  Link,
  SmTextSemibold,
  XsTextMd,
  XsTextSemiBold,
  VerifyTooltip,
  LevelTooltip,
  BaseButton,
} from "..";
import useCustomHooks from "./useCustomHooks";
import { getUser } from "redux/selectors/user";
import useAuth from "hooks/useAuth";
import { formatImgUrl } from "utils/helpers";
import { CIRCLE_VERIFIED_ICON } from "Assets/icons";
import { useIntl } from "react-intl";
import { PATHS } from "constants/routes";
import { Link as LinkDom } from "react-router-dom";
import { limitCountNoti } from "helpers/string";

const AuthBar = ({ isMobileView = false, hideUserName = false }) => {
  const user = useSelector(getUser) || {};
  const intl = useIntl();
  const userLevel = user.plan?.verification_level;

  const { isLoggedIn, onDirectLogin } = useAuth();
  // event
  const {
    expanded,
    anchorEl,
    menuItems,
    dialogConfig,
    onCloseDialog,
    handleExpand,
    onClickMenuItem,
    handleClickShowAuth,
    onClickLogout,
  } = useCustomHooks();

  const handleClickLogin = (e) => {
    if (isLoggedIn) {
      handleClickShowAuth(e);
    } else {
      onDirectLogin();
    }
  };

  return (
    <>
      <StyledAuthButton
        variant="text"
        startIcon={isLoggedIn ? <UserAvatar /> : <FiUser />}
        onClick={handleClickLogin}
        hideUserName={hideUserName}
      >
        {!hideUserName && (
          <SmTextSemibold>
            {isLoggedIn
              ? user?.customer_name
              : intl.formatMessage({ id: "login" })}
          </SmTextSemibold>
        )}
      </StyledAuthButton>
      {isMobileView && isLoggedIn && (
        <LogoutButton onClick={onClickLogout}>
          <IntlMsg id="Signout" />
        </LogoutButton>
      )}

      <StyledMenu
        open={expanded}
        anchorEl={anchorEl}
        onClose={() => handleExpand(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
        }}
      >
        <Box className="user-info">
          <Link onClick={onCloseDialog} to={PATHS.membership}>
            <VerifyTooltip
              title={
                <LevelTooltip
                  level={userLevel}
                  title="buyer_is_verified_by_freshdi"
                />
              }
            >
              <XsTextMd className="status-label green-label plan">
                <Img src={CIRCLE_VERIFIED_ICON} alt="icon" />
                {userLevel}
              </XsTextMd>
            </VerifyTooltip>
          </Link>

          <LinkDom to={PATHS.profile} onClick={onCloseDialog}>
            <Stack direction="row" spacing="12px" my={1.5}>
              <Img
                src={formatImgUrl(user.company_logo, 2)}
                className="avatar"
              />
              <div>
                <SmTextSemibold
                  sx={{ fontWeight: "600 !important" }}
                  mb={1}
                  className="cutoff-text oneline username"
                >
                  {user.company_name || user.customer_name}
                </SmTextSemibold>
                <XsTextMd className="status-label green-label">
                  {intl.formatMessage({ id: "buyer_account_f" })}
                </XsTextMd>
              </div>
            </Stack>
          </LinkDom>

          <Link to={PATHS.overview} onClick={onCloseDialog}>
            <BaseButton className="go-work-btn" fullWidth>
              {intl.formatMessage({ id: "go_to_company_workspace" })}
            </BaseButton>
          </Link>

          <XsTextSemiBold
            mt={2}
            sx={{ fontWeight: "600 !important" }}
            className="cutoff-text oneline"
          >
            {user.customer_name}
          </XsTextSemiBold>
          <XsTextMd mt={0.5} color="var(--gray)">
            {user.email}
          </XsTextMd>
        </Box>

        {menuItems?.map((menuItem, index) => {
          let dividerItem = index === menuItems.length - 2;

          return (
            <MenuItem
              key={index}
              onClick={() => onClickMenuItem(menuItem)}
              sx={menuItem?.sx}
              style={{
                borderTop: dividerItem ? "1px solid #D0D5DD" : "none",
                marginTop: dividerItem ? 10 : 0,
                marginBottom: index === menuItems.length - 1 ? 10 : 0,
              }}
            >
              <XsTextMd>{menuItem?.label}</XsTextMd>
              {Number(menuItem.count) > 0 && (
                <p className={"badge-number"}>
                  {limitCountNoti(menuItem.count)}
                </p>
              )}
            </MenuItem>
          );
        })}
      </StyledMenu>

      <StyledDialog open={dialogConfig.open} onClose={onCloseDialog}>
        {dialogConfig.content}
      </StyledDialog>
    </>
  );
};

const UserAvatar = () => {
  const user = useSelector(getUser);

  const formatUserName = (name = "") => {
    return name && name?.length > 0 && name[0];
  };

  return (
    <StyledAvatar
      alt="icon"
      src={user?.user_image ? formatImgUrl(user?.user_image, 2) : undefined}
    >
      {user?.customer_name && formatUserName(user?.customer_name)}
    </StyledAvatar>
  );
};

export const ConfirmLogoutPopup = (props) => {
  const { onClickCancel, onClickConfirm } = props;
  return (
    <Box p={2}>
      <Typography>
        <IntlMsg id="Components.AuthBar.confirmLogout" />
      </Typography>
      <ConfirmBtns
        cancelEvent={onClickCancel}
        acceptEvent={onClickConfirm}
        att={confirmBtnStyles}
      />
    </Box>
  );
};

export default AuthBar;
